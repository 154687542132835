import * as React from "react";
import "./recipe.scss";
import {UserRecipe, CustomUserRecipe, isError, deResponsify} from "../../api/interfaces";
import contentManager, {ContentEvents, ContentDataType} from "../../api/contentManager";
import {RouteComponentProps} from "react-router-dom";
import api from "../../api/api";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import ArrowImg from "./../../img/link-arrow.svg";
import Loader from "./../misc/Loader";
import RecipePlaceholderImg from "./../../img/recipe-placeholder.png";
import TagCloud from "../misc/TagCloud";
import Stars from "../misc/Stars";
import Plan from "../plan/Plan";

interface RecipePropsMatchParams {
    id: string;
    action: string;
}

export interface RecipeComponentProps extends RouteComponentProps<RecipePropsMatchParams> {}

export interface RecipeComponentState {
    mode?: string;
    uploadedImage?: string;
    recipe?: UserRecipe;
    customRecipe: CustomUserRecipe;
    planning?: boolean;
}

class RecipeComponent extends React.Component<RecipeComponentProps, RecipeComponentState> {
    titleRef = React.createRef<HTMLInputElement>();
    tagEntryRef = React.createRef<HTMLInputElement>();
    shouldView?: boolean;

    constructor(props: RecipeComponentProps) {
        super(props);
        this.state = {mode: undefined, uploadedImage: undefined, recipe: undefined, customRecipe: {title: "", isPublic: false}};
        this.handleContent = this.handleContent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidlMount() {
        document.title =  "Recept | " + process.env.REACT_APP_SITE_TITLE
    }

    handleContent() {
        this.setState(
            {
                recipe: contentManager.data.recipes.find(item => item.id === parseInt(this.props.match.params.id)),
            },
            () => {
                if (this.state.customRecipe.tags === undefined) {
                    this.setState({customRecipe: {...this.state.customRecipe, tags: this.state.recipe?.tags?.map(tag => tag.text)}});
                }
            }
        );
    }

    componentDidMount() {
        this.shouldView = true;
        this.handleContent();
        contentManager.addListener(ContentEvents.newContent, this.handleContent);
        contentManager.addVisibleType(ContentDataType.recipes);
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.handleContent);
        contentManager.removeVisibleType(ContentDataType.recipes);
    }

    renderIngredientList(): JSX.Element[] | undefined {
        if (this.state.recipe && this.state.recipe.ingredients) {
            return this.state.recipe.parsedIngredients?.list.map((item, index) => <li key={index}>{item.text}</li>);
        }
        return [];
    }

    renderInstructionList(): JSX.Element[] {
        if (this.state.recipe && this.state.recipe.instructions) {
            return this.state.recipe.instructions.split("\n").map((item, index) =>
                <li key={index}>
                    <input id={"cb"+index} type="checkbox" />
                    <label htmlFor={"cb"+index}>{item}</label>
                </li>);
        }
        return [];
    }

    handleChange(e: React.FormEvent<HTMLSelectElement>) {
        var safeSearchTypeValue: string = e.currentTarget.value;
        console.log(safeSearchTypeValue);
    }

    handleImageChange(e: any) {
        if (e.target.files && e.target.files[0]) {
            let _this = this
            let file = e.target.files[0];
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                if (reader.result) {
                    if (typeof reader.result === 'string') {
                        _this.setState({uploadedImage: URL.createObjectURL(file)})
                        // Strip the image into a clean base64-string
                        let base64Img = reader.result;
                        base64Img = base64Img.replace(/^data:image\/[a-z]+;base64,/, "");

                        _this.setState({customRecipe: {..._this.state.customRecipe, image: base64Img}})

                        // this.setState({customRecipe: {...this.state.customRecipe, image: URL.createObjectURL(img)}})
                    }
                }
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };

        }
        else {
            this.setState({customRecipe: {...this.state.customRecipe, image: ""}})
        }
    }

    async handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (!this.titleRef.current) {
            return;
        }
        if ((this.titleRef.current.value.length || 0) < 1) {
            window.alert("Du måste ange en titel.");
            return;
        }

        this.setState({customRecipe: {...this.state.customRecipe, title: this.titleRef.current.value}}, async () => {

            if (this.state.recipe) {
                //console.log ("Should update the recipe...")
                const recipe = await api.recipes.updateRecipe(this.state.recipe.id, this.state.customRecipe);
                if (!isError(recipe)) {
                    // console.log ("Saved - id:", recipe.id)
                    contentManager.patchRecipe(deResponsify(recipe));
                    this.props.history.push("/recipe/" + this.state.recipe?.id);

                } else {
                    console.error ("Something went wrong!!")
                }
            } else {
                // console.log ("Should create a recipe...")
                const recipe = await api.recipes.createRecipe(this.state.customRecipe);

                contentManager.fetchData(new Set([ContentDataType.recipes]));
                if (!isError(recipe)) {
                    // console.log ("Created - id:",  recipe.id)
                    this.props.history.push("/recipe/" + recipe.id);
                } else {
                    console.error ("Something went wrong!!")
                }
            }
        });
    }

    renderReadMode(): JSX.Element | undefined {
        document.title =  this.state.recipe?.title + " | Recept | " + process.env.REACT_APP_SITE_TITLE

        window.onbeforeunload = null;
        if (this.shouldView && this.state.recipe) {
            api.recipes.postView(this.state.recipe.id);
            this.shouldView = false;
        }

        var cookTime: string = "";
        if (this.state.recipe?.cookTime) {
           cookTime = this.state.recipe?.cookTime + " min"
        }
        else if (this.state.recipe?.originalCookTime) {
           cookTime = "Mellan " + this.state.recipe?.originalCookTimeSpanLower + " och " + this.state.recipe?.originalCookTime +" min "
        } else if (this.state.recipe?.originalCookTimeSpanLower) {
           cookTime = " Under "+ this.state.recipe?.originalCookTimeSpanLower +" min "
        }

        return (
            <div className="recipe-data loaded">
                <div className="recipe-header">
                    <div className="recipe-summary">
                        <div>
                            {cookTime ? <div> {cookTime} </div> : ""}
                            {this.state.recipe?.rating && (
                                <div className="rating">
                                    Ditt betyg:
                                    <Stars maxCount={5} value={this.state.recipe.rating} key={this.state.recipe.rating} />
                                </div>
                            )}
                        </div>

                        {this.state.recipe?.source && (
                            <div className="source">
                                {" Källa:  "}
                                <a href={this.state.recipe?.source?.url} target="_blank" rel="noopener noreferrer">
                                    {this.state.recipe?.source?.text}
                                </a>
                            </div>
                        )}
                    </div>
                    <h5 className="recipe-title">
                        {this.state.recipe?.title}

                        <DropdownMenu
                            items={[
                                {
                                    text: "Planera",
                                    callback: () => {
                                        this.setState({planning: true});
                                    },
                                },
                                {
                                    text: "Ändra",
                                    callback: () => {
                                        this.props.history.push("/recipe/" + this.state.recipe?.id + "/edit");
                                    },
                                },

                                {
                                    text: "Ta bort",
                                    callback: () => {
                                        const theid = this.state.recipe ? this.state.recipe?.id : 0;
                                        if (window.confirm("Är du säker på att du vill ta bort detta recept?")) {
                                            api.recipes.deleteRecipe(theid).then(() => contentManager.removeRecipe(theid));
                                            this.props.history.push("/recipes");
                                        }
                                    },
                                },
                            ]}
                        />
                    </h5>
                    <p>{this.state.recipe?.description}</p>
                </div>

                <hr />

                <div className="recipe-content columns">
                    <div className="left-col col">
                        <h6>Gör så här</h6>
                        <ul className="recipe-instructions checkboxes">{this.renderInstructionList()}</ul>
                    </div>
                    <div className="right-col">
                        <h6>
                            Ingredienser
                            <DropdownMenu
                                items={
                                    this.state.recipe?.selectableYields?.list.map(value => {
                                        return {
                                            text: value + " " + this.state.recipe?.portionType,
                                            callback: () => {
                                                if (this.state.recipe) {
                                                    api.recipes.patchRecipe(this.state.recipe.id, {selectedPortions: value}).then(newRecipe => {
                                                        if (!isError(newRecipe)) {
                                                            contentManager.patchRecipe(deResponsify(newRecipe));
                                                        }
                                                    });
                                                }
                                            },
                                        };
                                    }) || []
                                }
                                className="secondary"
                                title={
                                    (this.state.recipe?.selectedPortions || this.state.recipe?.selectableYields?.default) + " " + this.state.recipe?.portionType
                                }
                            ></DropdownMenu>
                        </h6>
                        <ul className="recipe-ingredients">{this.renderIngredientList()}</ul>

                        <div className="addtobasket link" onClick={() => this.setState({planning: true})}>
                            Planera och lägg till i inköpslista <img alt="" src={ArrowImg} />
                        </div>
                    </div>
                </div>

                <hr />

                <div className="recipe-footer columns">
                    <div className="left-col">
                        <h6>Dina anteckningar</h6>
                        <p>{this.state.recipe?.notes || "Det finns inga anteckningar"}</p>
                    </div>
                    <div className="right-col">
                        <h6>Tips</h6>
                        {this.state.recipe?.advice?.split("\n").map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                </div>
                <div className="columns">
                    <div>
                        <h6>Etiketter</h6>
                        <TagCloud tags={new Set<string>((this.state.recipe?.tags || []).map(tag => tag.text))} />
                    </div>
                </div>
                {this.state.recipe && this.state.planning ? <Plan recipe={this.state.recipe} onClose={() => this.setState({planning: undefined})} /> : ""}
            </div>
        );
    }

    renderEditMode(action = ""): JSX.Element | undefined {
        document.title =  (action ==="add" ? "Skapa nytt recept" : "Ändra recept") + " | " + process.env.REACT_APP_SITE_TITLE

        return (
            <form
                onSubmit={this.handleSubmit}
                onReset={() => this.setState({customRecipe: {title: this.state.recipe?.title || "", isPublic: this.state.recipe?.isPublic || false}})}
            >
                <div className="recipe-data loaded">
                {this.state.recipe?.isOwn || action ==="add"?
                    <input name="imageFile"
                        id="file"
                        type="file"
                        accept="image/*,.jpg,.jpeg"
                        multiple={false}
                        className="upload-image"
                        onChange={ (e) => this.handleImageChange(e) } />
                    : "" }
                    <h5 className="recipe-title">{action === "add" ? "Skapa nytt recept" : "Ändra recept"}</h5>

                    <div>
                        <div className="columns recipe-summary">
                            <input
                                name="cookTime"
                                type="number"
                                className="full-width"
                                onSubmit={e => e.preventDefault()}
                                min="0"
                                defaultValue={this.state.recipe?.cookTime}
                                onChange={e => this.setState({customRecipe: {...this.state.customRecipe, cookTime: e.currentTarget.valueAsNumber || 0}})}
                                onBlur={e => (e.currentTarget.value = "" + (this.state.customRecipe.cookTime || ""))}
                                placeholder="Skriv tillagningstid i minuter här"
                            />
                            <div className="rating">
                                    Ditt betyg:
                                    <Stars maxCount={5}
                                        value={this.state.recipe?.rating}
                                        editable={true}
                                        key={this.state.recipe?.rating}
                                        callback={value => this.setState({customRecipe: {...this.state.customRecipe, rating: value}})}
                                         />

                            </div>
                        </div>
                    </div>

                    <input
                        name="title"
                        type="text"
                        className="full-width recipe-title"
                        minLength={1}
                        ref={this.titleRef}
                        defaultValue={this.state.recipe?.title}
                        placeholder="Titel på ditt recept"
                        onChange={e => this.setState({customRecipe: {...this.state.customRecipe, title: e.currentTarget.value}})}
                    />

                    <textarea
                        defaultValue={this.state.recipe?.description}
                        name="description"
                        onChange={e => this.setState({customRecipe: {...this.state.customRecipe, description: e.currentTarget.value}})}
                        className="full-width"
                        placeholder="Beskriv receptet här"
                    />

                    <hr />

                    <div className="columns">
                        <div className="">
                            <h6>Instruktioner</h6>
                            <textarea
                                name="instructions"
                                className="full-width tall "
                                placeholder="Skriv in steg-för-steg-instruktioner, med eller utan numrering"
                                defaultValue={this.state.recipe?.instructions}
                                onChange={e => this.setState({customRecipe: {...this.state.customRecipe, instructions: e.currentTarget.value}})}
                            />
                        </div>
                        <div className="">
                            <div className="portions">
                                <div>
                                    <input
                                        name="portions"
                                        type="number"
                                        className="value"
                                        min="0"
                                        defaultValue={this.state.recipe?.portions ? this.state.recipe?.portions : "1"}
                                        onChange={e => this.setState({customRecipe: {...this.state.customRecipe, portions: e.currentTarget.valueAsNumber}})}
                                    />
                                    <select
                                        name="portionType"
                                        className="type"
                                        defaultValue={this.state.recipe?.portionType ? this.state.recipe?.portionType : "sats"}
                                        onChange={e => this.setState({customRecipe: {...this.state.customRecipe, portionType: e.currentTarget.value}})}>
                                        <option>bit</option>
                                        <option>bitar</option>
                                        <option>bröd</option>
                                        <option>bullar</option>
                                        <option>bulle</option>
                                        <option>glas</option>
                                        <option>liter</option>
                                        <option>sats</option>
                                        <option>satser</option>
                                        <option>styck</option>
                                        <option>stycken</option>
                                        <option>person</option>
                                        <option>personer</option>
                                        <option>portion</option>
                                        <option>portioner</option>
                                    </select>
                                </div>
                            </div>
                            <h6>Ingredienser </h6>
                            <textarea
                                name="ingredients"
                                className="full-width tall ingredients"
                                placeholder="Lista alla ingredienser här. &#10;&#0;&#10;En ingrediens per rad (ex '2 dl grädde')."
                                defaultValue={this.state.recipe?.ingredients}
                                onChange={e => this.setState({customRecipe: {...this.state.customRecipe, ingredients: e.currentTarget.value}})}
                            />

                            <div className="info">Delrubriker kan användas med hjälp av kolon (ex 'Sås:')</div>
                        </div>
                    </div>

                    <hr />

                    <div className="columns">
                        <div>
                            <h6>Dina anteckningar</h6>
                            <textarea
                                name="notes"
                                className="full-width tall"
                                placeholder="Dessa anteckningar syns aldrig för andra användare"
                                defaultValue={this.state.recipe?.notes}
                                onChange={e => this.setState({customRecipe: {...this.state.customRecipe, notes: e.currentTarget.value}})}
                            />
                        </div>
                        <div>
                            <h6>Tips</h6>
                            <textarea
                                defaultValue={this.state.recipe?.advice}
                                name="advice"
                                className="full-width tall"
                                placeholder="Tips till kocken"
                                onChange={e => this.setState({customRecipe: {...this.state.customRecipe, advice: e.currentTarget.value}})}
                            />
                        </div>
                    </div>

                    <div className="columns">
                        <div>
                            <h6>Etiketter</h6>
                            <TagCloud
                                callback={selectedTags => {
                                    var tags = this.state.customRecipe.tags?.filter(e => !selectedTags.has(e));
                                    this.setState({customRecipe: {...this.state.customRecipe, tags: tags}});
                                }}
                                tags={new Set<string>(this.state.customRecipe.tags)}
                            />

                            <input
                                ref={this.tagEntryRef}
                                type="text"
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        const tags = this.state.customRecipe.tags || [];
                                        tags.push(this.tagEntryRef.current?.value || "");
                                        if (this.tagEntryRef.current) {
                                            this.tagEntryRef.current.value = "";
                                        }
                                        this.setState({
                                            customRecipe: {
                                                ...this.state.customRecipe,
                                                tags: tags,
                                            },
                                        });
                                    }
                                }}
                                placeholder="Lägg till..."
                            />
                        </div>
                    </div>

                    <div className="columns sticky_buttons">
                        <div>
                            <input type="submit" className="button full-width" value="Spara" />
                        </div>
                        <div>
                            <input type="reset" className="button secondary full-width" value="Återställ" />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
    changeRenderMode(newmode: string) {
        if (newmode!==this.state.mode)
            this.setState({mode: newmode})
    }
    renderMode(): JSX.Element | undefined {
        if (this.state.recipe) {
            if (this.props.match.params.action === "edit") {
                this.changeRenderMode("edit")
                return this.renderEditMode("edit");
            } else {
                this.changeRenderMode("read")
                return this.renderReadMode();
            }
        } else {
            if (this.props.match.params.id === "add") {
                this.changeRenderMode("add")
                return this.renderEditMode("add");
            } else {
                this.changeRenderMode("")
                return <Loader />;
            }
        }
    }

    render() {
        var readBgImage = null, editBgImage = null, bgImage = null
        readBgImage = this.state.recipe?.imageUrls ? this.state.recipe.imageUrls[0].url : <RecipePlaceholderImg />;
        editBgImage = this.state.uploadedImage!==undefined ? this.state.uploadedImage : readBgImage;
        bgImage = this.state.mode==="read"? readBgImage : editBgImage;

        return (
            <div className="recipe card_wrapper">
                <div className="card">
                    <div className="image" style={{backgroundImage: "url(" + bgImage + ")"}}></div>

                    {this.renderMode()}
                </div>
            </div>
        );
    }
}

export default RecipeComponent;
