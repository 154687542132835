import * as React from "react";
import "./login.scss";
import api from "../../api/api";
import {RouteComponentProps, Link} from "react-router-dom";


export interface ResetPasswordState {
    email: string;
    key: string;
    message: string;
    error: boolean;
    disabled: boolean;
    password: string;
    passwordAgain: string;
}

export default class ResetPassword extends React.Component<RouteComponentProps, ResetPasswordState> {

    ref = React.createRef<HTMLInputElement>();

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            email: "",
            key: "",
            message: "",
            error: false,
            disabled: false,
            password: "",
            passwordAgain: ""
        };
    }
    componentDidMount() {
        document.title =  "Återställ lösenord | " + process.env.REACT_APP_SITE_TITLE

        const query = new URLSearchParams(this.props.location.search);
        const email = query.get('username')
        const key = query.get('key')
        console.log(email, key)

        if (email && key) {
          this.setState({
              email: email,
              key: key
          });
        }
    }

    resetPasswordRequest() {
        return (
            <div id="password-reset-form" className="login">
                <div>
                    <h6 className="title">Återställ lösenord</h6>
                    <p className="subtitle"> &nbsp; </p>
                    <form
                        onSubmit={async e => {
                            this.setState({disabled: true});
                            e.preventDefault();
                            if (this.ref.current) {
                                const response = await api.forgotPassword(this.ref.current.value);
                                if (response.statusCode === 400) {
                                    this.setState({message: "Mejladessen hittades inte.", error: true, disabled: false});
                                } else if (response.ok) {
                                    this.setState({message: "<span>Email med instruktioner för att återställa lösenordet skickat!</span>", error: false});
                                } else {
                                    this.setState({message: "Något gick fel, försök igen.", error: true, disabled: false});
                                }
                            }
                        }}
                    >
                        <input
                            type="email"
                            className={this.state.error ? "full-width highlight" : "full-width"}
                            placeholder="Email" ref={this.ref}
                            disabled={this.state.disabled}
                        />
                        <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message }} />

                        <input type="submit" value="Skicka" className={"button" + (this.state.disabled? " disabled" : "") } />
                    </form>
                    <br/>
                    <Link to="/login/email">
                        ← Tillbaka till login
                    </Link>
                </div>
            </div>
        )
    }

    // https://www.test.matlistan.se/Account/ResetPassword/?username=johan.montan%2btest11%40intunio.se&key=E3289FB7AC733C2E0227740483BAFDE1
    resetPasswordForm() {
        return (
            <div id="password-reset-form" className="login">
                <div>
                    <h6 className="title">Skapa ett nytt lösenord</h6>
                    <p className="subtitle"> &nbsp; </p>
                    <form
                        onSubmit={async e => {
                            e.preventDefault();

                            if (this.state.password !== "") {

                                if ( this.state.password.length <8 ) {
                                    this.setState({message: "Lösenordet måste innehålla minst 8 tecken.", error: true});
                                } else if ( this.state.password !== this.state.passwordAgain ){
                                    this.setState({message: "Det nya lösenordet och upprepningen stämmer inte överens.", error: true});
                                } else {

                                      this.setState({disabled: true});
                                      var data = {email: this.state.email,
                                                  key: this.state.key,
                                                  password: this.state.password}
                                      const response = await api.resetPassword(data);
                                          console.log(response)
                                      if (response.statusCode === 406) {
                                          this.setState({message: "Ogiltligt lösenord, försök igen.", error: true, disabled: false});
                                      } else if (response.ok) {
                                          this.setState({message: "<span>Sådär... Lösenord är återställt!</span>", error: false});
                                      } else {
                                          this.setState({message: "Något gick fel, försök igen.", error: true, disabled: false});
                                      }

                                }
                            }
                        }}
                    >
                        <input
                            type="password"
                            autoComplete="new-password"
                            className={this.state.error ? "full-width highlight" : "full-width"}
                            placeholder="Nytt lösenord"
                            disabled={this.state.disabled}
                            onChange={e => this.setState({password: e.currentTarget.value})}
                        />
                        <input
                            type="password"
                            autoComplete="new-password"
                            className={this.state.error ? "full-width highlight" : "full-width"}
                            placeholder="Upprepa lösenordet"
                            disabled={this.state.disabled}
                            onChange={e => this.setState({passwordAgain: e.currentTarget.value})}
                        />
                        <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message }} />

                        <input type="submit" value="Byt lösenord" className={"button" + (this.state.disabled? " disabled" : "") } />
                    </form>
                    <br/>
                    <Link to="/login/email">
                        ← Tillbaka till login
                    </Link>
                </div>
            </div>
        )
    }

    render() {
        return (

            (this.state.email && this.state.key)?
            this.resetPasswordForm()
            :
            this.resetPasswordRequest()

    );
  }

}
