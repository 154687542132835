import * as React from "react";
import "./bottombarlinks.scss";
import {NavLink} from "react-router-dom";

export interface BottomBarLinksProps {}
export interface BottomBarLinksState {}

class BottomBarLinks extends React.Component<BottomBarLinksProps, BottomBarLinksState> {
    constructor(props: BottomBarLinksProps) {
        super(props);
        this.state = {};
    }

    scrollTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        return (
            <div className="bottombar">
                <NavLink onClick={this.scrollTop} to="/faq">Vanliga frågor</NavLink>
                <span/>
                <NavLink onClick={this.scrollTop} to="/terms">Villkor</NavLink>
                <span/>
                <NavLink onClick={this.scrollTop} to="/privacypolicy">Integritetspolicy</NavLink>
                <span/>
                <NavLink onClick={this.scrollTop} to="/cookiepolicy">Cookiepolicy</NavLink>
                <span/>
                <NavLink onClick={this.scrollTop} to="/contact">Kontakt</NavLink>
            </div>
        );
    }
}

export default BottomBarLinks;
