import * as React from "react";
import DropdownButton from "./DropdownButton";
import "./dropdownmenu.scss";

export interface DropdownItem {
    text: string;
    callback?: any;
    className?: string;
    bold?: boolean;
}

export interface DropdownMenuProps {
    items: DropdownItem[];
    className?: string;
    title?: string;
}

export interface DropdownMenuState {
    showMenu: boolean;
}

class DropdownMenu extends React.Component<DropdownMenuProps, DropdownMenuState> {
    constructor(props: DropdownMenuProps) {
        super(props);
        this.state = {showMenu: false};
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();

        this.setState({showMenu: true}, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu() {
        this.setState({showMenu: false});
        document.removeEventListener("click", this.closeMenu);
    }

    componentWillUnmount() {
        this.closeMenu();
    }

    render() {
        const childs = this.props.items.map((item, index) => {
            return (
                <DropdownButton key={index} className={item.className} callback={item.callback} bold={item.bold}>
                    {item.text}
                </DropdownButton>
            );
        });
        return (
            <div
                className={"more-menu "
                    + (this.props.className ? this.props.className : "")
                    + (this.props.title ? " have_title" : "")
                    + (this.state.showMenu ? " open" : "")
                }
                onClick={this.showMenu.bind(this)}
            >
                <div className="title"> {this.props.title} </div>
                <div className="children">
                    <div>{childs}</div>
                </div>
            </div>
        );
    }
}

export default DropdownMenu;
