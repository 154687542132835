import * as React from "react";
import {NavLink, RouteComponentProps} from "react-router-dom";
import BottomBarLinks from "../misc/BottomBarLinks";
import "./faq.scss";
import data from "./faq.json";


interface FaqPropsMatchParams {
    id: string;
    subid: string;
}

export interface FaqProps extends RouteComponentProps<FaqPropsMatchParams> {}

export interface FaqState {
  expanded: string
}

class Faq extends React.Component<FaqProps, FaqState> {

    constructor(props: FaqProps) {
        super(props);
        this.state = {
            expanded: ""
        };
    }

    componentDidMount() {
        document.title =  "Vanliga frågor | " + process.env.REACT_APP_SITE_TITLE
    }


    toggleExpand(obj: string) {
        if(this.state.expanded === obj) {
            this.setState({expanded: ""})
        } else {
            this.setState({expanded: obj})
        }
    }

  render() {
    return (
      <div className="faq">
          <div className="card_wrapper">

              {this.props.match.params.id?

              <div className="card">
                <NavLink to={"/faq"}>  « Vanliga frågor </NavLink>
                {
                  data[parseInt(this.props.match.params.id)]?.questions[parseInt(this.props.match.params.subid)].more?.map((subItem, c) => {
                    return (
                            <div key={c}>
                              <h6>{subItem.title} </h6>
                              <div>
                                  <span dangerouslySetInnerHTML={ { __html: subItem.body } }></span>
                              </div>
                            </div>

                    );
                  })

                  }
              </div>
              :
              <div className="card">
                  <h2>Vanliga frågor</h2>
                  <p>Denna sida innehåller de villkor som gäller för användningen av Matlistan. </p>
                  <p>Genom att använda dig av Matlistan godkänner du villkoren. Vad vi gör för att
                  skydda dina rättigheter och din information kan du läsa mer om i integritetspolicyn.
                  </p>

                  <div className="columns">
                  {
                  data.map((faq, a) => {
                    return (
                      <div key={a}>
                        <h4>{faq.title}</h4>
                        <div>
                          {
                            faq.questions.map((faqItem, b) => {
                              return (
                                  <div key={a+"."+b} className={"item" + (this.state.expanded === (a+"."+b)? " expanded" : "")}>
                                    <h6 className="title" onClick={() => this.toggleExpand(a+"."+b)}>{faqItem.title} </h6>
                                    <div>
                                        <span dangerouslySetInnerHTML={ { __html: faqItem.body } }></span>
                                        {faqItem.more?
                                            <NavLink to={"faq/"+a+"/"+b}>Läs mer här »</NavLink>
                                        :
                                            ""
                                        }
                                    </div>
                                  </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    );
                  })
                  }

                  </div>
              </div>
            }
          </div>

          <BottomBarLinks />

      </div>
    );
  }
}

export default Faq;
