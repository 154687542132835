import * as React from "react";
import "./login.scss";
import api from "../../api/api";
import {withRouter, RouteComponentProps, Link, Switch, Route} from "react-router-dom";
import GoogleLogin from 'react-google-login';
import FacebookLogin from "react-facebook-login";
import LoginEmail from "./LoginEmail";
import ResetPassword from "./ResetPassword";

const navigateAfterLogin: string = "/list/"


export default class Login extends React.Component<RouteComponentProps> {
    componentDidMount() {
        document.title =  "Logga in | " + process.env.REACT_APP_SITE_TITLE
    }

    render() {
      return (
        <Switch>
            <Route path="/login/email" exact component={withRouter(LoginEmail)} />
            <Route path="/login/resetpassword" exact component={ResetPassword} />
            <Route exact path="/login" component={withRouter(ChooseProvider)} />
        </Switch>
      );
    }
}

class ChooseProvider extends React.Component<RouteComponentProps> {

    // FACEBOOK login
    async responseFacbookSuccess (response: any ){
        console.log(response);
        await api.auth ({ fbAccessToken: response.accessToken })

        if (api.authenticated) {
            this.props.history.push(navigateAfterLogin)
        } else {
            console.warn("Unable to authenticate by Facebook 😫")
        }
    }
    responseFacbookFailed (){
        console.warn("Failed to login via Facebook")
    }

    //GOOGLE login
    async responseGoogleSuccess (response: any ){
        console.log(response);
        await api.auth ({ gIdToken: response.tokenId })

        if (api.authenticated) {
            this.props.history.push(navigateAfterLogin)
        } else {
            console.warn("Unable to authenticate by Google 😫")
        }
    }
    responseGoogleFailed (response: any ){
        console.warn("Failed to login via Google", response)
    }

    render() {

        const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
        const TEST_DOMAINS = ["matlistan.intun.io", "intun.io", "test.matlistan.se"];
        var facebookAppId: string,
            googleClientId: string;

        if (LOCAL_DOMAINS.includes(window.location.hostname)) {
            facebookAppId = "655391381227869"  // debug/localhost
            googleClientId = "928573490278-qt740eivsd23apk6fsnqmc095miehs98.apps.googleusercontent.com"  // debug/localhost
            console.log("Google & Facebook login for 'debug/localhost'")
        }
        else if (TEST_DOMAINS.includes(window.location.hostname)) {
            facebookAppId = "643062699127404"  // Test
            googleClientId = "928573490278-k1g4796fufggv6aedmha1is3q1b2i99b.apps.googleusercontent.com"  //Test
            console.log("Google & Facebook login for 'Test'")
        }
        else {
            facebookAppId="160944750672537"  //Production
            googleClientId="928573490278-q39ki0bms1aql1e43695fubl1bmpkm4n.apps.googleusercontent.com"  //Production
        }

        const query = new URLSearchParams(this.props.location.search);
        const confirm = query.get('confirm')

        return (
            <div id="login-provider-container" className="login">

            {!api.authenticated === true?
                <div>
                    <h6>Välj inloggningssätt</h6>

                    <FacebookLogin
                        appId = {facebookAppId}
                        fields="name,email"
                        callback={(e) => this.responseFacbookSuccess(e)}
                        onFailure={() => this.responseFacbookFailed()}
                        cssClass="provider facebook"
                        textButton="Facebook"
                        language="sv_SE"
                    />

                    <GoogleLogin
                        clientId = {googleClientId}
                        render={renderProps => (
                            <button type="button"
                                onClick={renderProps.onClick}
                                className="provider google">Google</button>
                        )}
                        buttonText="Login"
                        onSuccess={(e) => this.responseGoogleSuccess(e)}
                        onFailure={(e) => this.responseGoogleFailed(e)}
                        cookiePolicy={'single_host_origin'}
                    />


                    <Link className="provider email" to={"/login/email" + (confirm? "?confirm="+confirm : "") }>
                        Mejladress
                    </Link>

                    <p>
                        Du kan välja att använda Facebook, <br />
                        Google eller mejladress för att skapa ett <br />
                        nytt konto eller logga in.
                    </p>
                </div>
                :
                <div>
                    <h6>Välj inloggningssätt</h6>
                    <p> &nbsp; </p>
                    <p> Nämen! Du är redan inloggad. </p>

                    <button className="button " onClick={() => api.deauth()} > &nbsp; &nbsp; Logga ut &nbsp; &nbsp; </button>

                </div>
                }
            </div>
        );
      }
    }
