import * as React from "react";
import contentManager, {ContentEvents, RichList, ContentDataType} from "../../api/contentManager";
import "./lists.scss";
import {Link, withRouter, RouteComponentProps} from "react-router-dom";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import api from "../../api/api";
import {isError} from "../../api/interfaces";

export interface ListsPageProps extends RouteComponentProps {}

export interface ListsPageState {
    lists: Array<RichList>;
}

class ListsPage extends React.Component<ListsPageProps, ListsPageState> {
    constructor(props: ListsPageProps) {
        super(props);
        this.state = {lists: []};

        this.updateContentState = this.updateContentState.bind(this);
        this.ListsList = this.ListsList.bind(this);
    }

    async updateContentState() {
        this.setState({lists: contentManager.getRichLists()});
    }

    componentDidMount() {
        document.title =  "Alla inköpslistor | " + process.env.REACT_APP_SITE_TITLE
        contentManager.addListener(ContentEvents.newContent, this.updateContentState);

        if (contentManager.hasData) {
            this.updateContentState();
        }

        contentManager.addVisibleType(ContentDataType.itemLists);
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.updateContentState);
        contentManager.removeVisibleType(ContentDataType.itemLists);
    }

    ListsList(): JSX.Element {
        return (
            <div>
                {this.state.lists.map((list, key) => {
                    return (
                        <div className="list-card" key={key}>
                            <DropdownMenu
                                items={[
                                    {
                                        text: "Byt namn",
                                        callback: () => {
                                            const new_name = window.prompt("Byt namn på lista", list.name);
                                            if (new_name) {
                                                api.itemLists
                                                    .updateList(list.id, {
                                                        name: new_name,
                                                    })
                                                    .then(new_list => {
                                                        if (!isError(new_list)) {
                                                            contentManager.patchItemList(new_list);
                                                        }
                                                    });
                                            }
                                        },
                                    },
                                    {
                                        text: "Ta bort",
                                        callback: () => {
                                            if (window.confirm("Är du säker?"))
                                                api.itemLists.deleteList(list.id).then(() => contentManager.removeItemList(list.id));
                                        },
                                    },
                                ]}
                            />
                            <Link to={"./list/" + list.id} className="list-card-title">
                                {list.name}
                            </Link>
                            <div className="list-card-content">
                                {list.items
                                    .map(item => {
                                        return item.text;
                                    })
                                    .join(", ")}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="card_wrapper">
                <div className="card">
                    <h6>
                        <div
                            className="button button_title"
                            onClick={() => {
                                const name = window.prompt("Namn:");
                                if (name) {
                                    api.itemLists.createList({name: name}).then(() => {
                                        contentManager.fetchData(new Set([ContentDataType.itemLists]));
                                    });
                                }
                            }}
                        >
                            Skapa ny lista
                        </div>
                        Alla inköpslistor
                    </h6>
                    <div id="lists-list">
                        <this.ListsList />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListsPage);
