import * as React from "react";
import {NavLink} from "react-router-dom";
import BottomBarLinks from "../misc/BottomBarLinks";

class PrivacyPolicy extends React.Component {

  componentDidMount() {
      document.title =  "Integritetspolicy | " + process.env.REACT_APP_SITE_TITLE
  }

  render() {
    return (
      <div className="privacypolicy">
          <div className="card_wrapper">
              <div className="card">
                  <h2>Integritetspolicy</h2>

                  <div>
                      <p>&nbsp;</p>
                      <p>Denna integritetspolicy (”Policyn”) beskriver hur ConsumIQ AB org. nr 556965–9682 (”vi”, ”vår” och ”oss”), som äger och driver tjänsten Nya Matlistan (”Tjänsten”), behandlar personuppgifter. Nedan kan du läsa om vilken information vi samlar in, varför och vad vi gör med den.</p>
                      <p>Det kan hända att vi behöver uppdatera eller ändra Policyn. Om så sker kommer vi att informera dig på ett lämpligt sätt och be dig att ta del av de ändringar som gjorts. Den senaste versionen av Policyn hittar du alltid på vår webbplats. Om du har några frågor angående vår policy och hur vi hanterar dina personuppgifter ber vi dig kontakta vår dataskyddsansvarige via e-post genom att skriva till&nbsp;<a href="mailto:info@matlistan.se" rel="noopener noreferrer" target="_blank">info@matlistan.se</a>.</p>


                      <h5>Vad är ”Nya Matlistan”?</h5>
                      <p>Tjänsten är en ny, omarbetad version av Matlistan®. Matlistan® är en tjänst för konsumenter som vill spara tid och förenkla vardagen genom att effektivisera planering och inköp av dagligvaror. Tjänsten finns både i form av mobila appar och som en webbtjänst.</p>


                      <h5>Hur mottar vi personuppgifter från dig?</h5>
                      <p>För att fullt ut kunna använda Tjänsten behöver vi motta och hantera vissa personuppgifter från dig i samband med att ett konto registreras.</p>
                      <ul>
                          <li>Information som du anger när du registrerar ett konto, såsom e-postadress och/eller namn samt Facebook-ID eller Google-ID samt relaterad information såsom datum;</li>
                          <li>Information som du skickar till oss när du använder Tjänsten, såsom IP-nummer och vem du länkar ditt konto med och delar listor och recept med;</li>
                          <li>Kommunikation som du skickar till oss som exempelvis frågor, felrapportering och användarenkäter (i den mån personuppgifter lämnas).</li>
                      </ul>


                      <h5>Vad för typ av ytterligare data samlar vi in?</h5>
                      <p>Information om hur och när Tjänsten används, exempelvis vilka sidor som besöks och vilka funktioner som används
                          <br />Information som är relaterad till aktivitet i Tjänsten såsom listor, varutexter och butiker som lagts till, butiksbesök och recept
                          <br />Utöver detta loggas viss information för att kunna kvalitetssäkra, felsöka &amp; ta fram statistik. Dessa loggar kan dock inte knytas direkt till dig, då informationen först anonymiseras.</p>



                      <h5>Varför vi samlar in den här informationen och hur vi använder den</h5>
                      <p>Vi samlar in den här informationen från dig för följande syften:</p>
                      <ul>
                          <li>För att kunna erbjuda dig Tjänsten och alla dess nuvarande och framtida funktioner, informera dig om tjänsten samt be om användarfeedback.</li>
                          <li>För att kunna analysera hur Tjänsten används, lösa problem och felaktigheter samt förbättra användarupplevelsen och vidareutveckla tjänsten.</li>
                      </ul>


                      <h5>Datalagring</h5>
                      <p>Vi lagrar din information så länge som ditt konto är aktivt och så länge som vi behöver för att kunna erbjuda dig Tjänsten. Om du vill stänga ned ditt konto och radera dina uppgifter kan du vända dig till&nbsp;<a href="mailto:info@matlistan.se" rel="noopener noreferrer" target="_blank">info@matlistan.se</a>.</p>

                      <h5>Vem har tillgång till din information?</h5>
                      <p>Tillgången till de system i vilka personuppgifterna lagras är begränsad till de av våra anställda som behöver uppgifterna inom ramen för sina arbetsuppgifter samt kontrakterade tjänsteleverantörer som behövs för att erbjuda och utveckla Tjänsten.</p>
                      <ul>
                          <li>Vi kommer inte att sälja eller hyra ut dina personuppgifter till tredje part.</li>
                          <li>Vi kommer inte att dela dina personuppgifter med tredje part i marknadsföringssyfte.</li>
                          <li>Vi kommer inte att dela med oss av några personliga uppgifter till tredje part, förutom då vi har din uttryckliga tillåtelse i varje fall. Däremot kan vi komma att dela med oss av anonymiserad och sammanställd information, såsom statistik, till utvalda partners.</li>
                      </ul>


                      <h5>Tjänsteleverantörer &amp; övriga</h5>
                      <p>För att vi ska kunna erbjuda dig Tjänsten, bedriva vår verksamhet och uppfylla lagar och regleringar kan vi komma att behöva lämna ut personuppgifter till följande:</p>
                      <ul>
                          <li>Leverantörer för i huvudsak IT-drifttjänster samt kommunikationstjänster såsom genomförande av kundundersökningar och administration av e-postutskick.</li>
                          <li>Leverantörer av IT-säkerhet; vi delar personuppgifter med leverantörer av IT-säkerhet när detta är nödvändigt enligt lag, för att skydda dig eller våra kunder och samarbetspartners eller för att skydda våra tjänster.</li>
                          <li>Rådgivare och potentiella köpare av vår verksamhet; om hela eller delar av&nbsp;Tjänsten säljs eller integreras med annan verksamhet kan dina personuppgifter lämnas till våra rådgivare samt eventuell köpare och dennes rådgivare.</li>
                          <li>Statliga myndigheter (såsom Polisen, Skatteverket och andra myndigheter); vi delar personuppgifter med myndigheter om vi är skyldiga att göra det enligt lag eller vid misstanke om brott.</li>
                      </ul>
                      <p>De flesta av de tredje parter som vi delar personuppgifter med enligt ovan utgör i förhållande till oss s.k. personuppgiftsbiträden. Dessa får bara behandla de överförda uppgifterna för vår räkning och i enlighet med våra uttryckliga instruktioner. Vi överför bara dina personuppgifter till sådana personuppgiftsbiträden för ändamål som är förenliga med de ändamål för vilka vi har samlat in uppgifterna och vi säkerställer genom skriftliga avtal med personuppgiftsbiträdena att de åtar sig att följa våra säkerhetskrav och begränsningar samt krav avseende internationell överföring av personuppgifter.</p>
                      <p>Myndigheter och i vissa fall även företag som vi överför personuppgifter till enligt ovan kan vara självständigt personuppgiftsansvariga för de överförda uppgifterna. När dina personuppgifter överförs till någon som är självständigt personuppgiftsansvarig styr vi inte över hur uppgifterna sedan behandlas, utan ansvaret för detta faller då på den myndighet eller det företag som överföringen skett till, innebärande bland annat att den myndigheten eller det företaget är skyldig att informera dig om dess behandling av dina personuppgifter och att säkerställa att behandlingen är laglig.</p>


                      <h5>Kommunikation via e-post, sociala medier och tjänsten</h5>
                      <p>Vi skickar sällan ut e-postmeddelanden, men det händer när vi har något viktigt att kommunicera. Vi vill bara kommunicera med dig om du vill bli kontaktad av oss. När du registrerar dig på tjänsten antar vi att du är intresserad av att erhålla information från oss. Du kan välja att sluta motta kommunikation från oss genom att klicka på länken som du hittar längst ner i varje e-postmeddelande eller genom skicka e-post till&nbsp;<a href="mailto:info@matlistan.se" rel="noopener noreferrer" target="_blank">info@matlistan.se</a>.</p>


                      <h5>Säkerhet</h5>
                      <p>Vi tar säkerhet på största allvar och har ambitionen att lagra all data så säkert som möjligt. Vi använder<em>&nbsp;Secure Sockets Layer&nbsp;</em>(”SSL”) för att kryptera personuppgifter när de förmedlas via Internet. Överföring av information via Internet är aldrig 100% säkert så det går tyvärr inte att garantera fullständig säkerhet. Dina kontouppgifter skyddas av ett lösenord som du väljer och vi rekommenderar att du väljer ett unikt lösenord utan någon personlig koppling.</p>


                      <h5>Rätten till tillgång</h5>
                      <p>Om du vill få information om vilka personuppgifter vi behandlar om dig kan du begära att få tillgång till uppgifterna. Informationen kommer då att lämnas i form av ett elektroniskt registerutdrag som anger vilka personuppgifter vi behandlar, för vilka ändamål vi behandlar dem, var uppgifterna har inhämtats från, till vilka tredje parter uppgifterna har överförts till samt hur länge uppgifterna kommer att lagras. För att få tillgång ber vi dig kontakta oss på&nbsp;<a href="mailto:info@matlistan.se" rel="noopener noreferrer" target="_blank">info@matlistan.se</a>.</p>


                      <h5>Rätten till ändring</h5>
                      <p>Du har rätt att utan dröjsmål få felaktiga uppgifter om dig rättade. Du har även rätt att komplettera ofullständiga uppgifter.</p>


                      <h5>Rätten till radering</h5>
                      <p>Du kan när som helst mejla oss och begära att din information, inklusive ditt konto, tas bort. Då rensas all information som du har givit oss, förutom:
                          <br />Dina butiksbesök och statistik såsom recepttillagningar anonymiseras istället för att tas bort, för att inte försämra upplevelsen för övriga användare.
                          <br />De egna recept du har lagt upp, inklusive eventuella bilder, finns kvar hos de personer som har sparat dem i sina receptsamlingar. Dina recept anonymiseras dock och allmänheten kommer inte längre att kunna söka fram dem.</p>
                      <p>Du har även rätt att utan dröjsmål få dina personuppgifter raderade om något av följande inträffar:</p>
                      <ol>
                          <li>personuppgifterna inte längre är nödvändiga för de ändamål för vilka de samlades in eller på annat sätt behandlas;</li>
                          <li>du återkallar ditt samtycke för en behandling som stödjer sig på samtycke och det finns inte någon annan rättslig grund för behandlingen;</li>
                          <li>du invänder mot en behandling som stödjer sig på en intresseavvägning och ditt skäl för invändningen väger tyngre än vårt berättigade intresse;</li>
                          <li>personuppgifterna har behandlats på ett olagligt sätt;</li>
                          <li>personuppgifterna måste raderas för att vi ska kunna uppfylla en rättslig förpliktelse.</li>
                      </ol>


                      <h5>Rätten till begränsning av behandling</h5>
                      <p>Du har rätt att begära att behandlingen av dina personuppgifter begränsas om något av följande alternativ är tillämpligt:</p>
                      <ol>
                          <li>du bestrider personuppgifternas korrekthet under en tid som ger oss möjlighet att kontrollera om uppgifterna är korrekta;</li>
                          <li>behandlingen är olaglig och du motsätter dig att uppgifterna raderas och i stället begär en begränsning av deras användning;</li>
                          <li>vi inte längre behöver personuppgifterna för ändamålen med behandlingen men du behöver dem för att kunna fastställa, göra gällande eller försvara rättsliga anspråk;</li>
                          <li>du har invänt mot en behandling som stödjer sig på en intresseavvägning och vi kontrollerar om våra berättigade skäl väger tyngre än dina berättigade skäl.</li>
                      </ol>
                      <p>Om behandlingen har begränsats i enlighet med denna punkt får sådana personuppgifter som begränsning av behandling ska ske för, med undantag för lagring, endast behandlas för att fastställa, göra gällande eller försvara rättsliga anspråk eller för att skydda tredje parts rättigheter eller skäl som rör ett viktigt allmänintresse för EU eller för en EU-medlemsstat.</p>


                      <h5>Rätten till dataportabilitet</h5>
                      <p>I de fall vår behandling av personuppgifter stödjer sig på ditt samtycke eller fullgörande av avtal har du rätt att begära att de uppgifter som berör dig och som du lämnat till oss överförs till en annan personuppgiftsansvarig. En förutsättning för detta är dock att överföringen är tekniskt möjlig och att den kan ske automatiserat.</p>


                      <h5>Återkallelse av samtycke</h5>
                      <p>I de fall vår behandling av dina personuppgifter grundar sig på ditt samtycke har du alltid rätt att när som helst återkalla ditt samtycke. Ett sådant tillbakadragande av samtycke påverkar inte lagligheten av behandling som skett utifrån ditt samtycke innan detta drogs tillbaka. Om du återkallar ditt samtycke kommer vi inte längre att behandla de personuppgifter som grundas på samtycket, såvida vi inte av legala skäl är förpliktade att fortsatt behandla dem. Skulle det vara så att våra legala skyldigheter hindrar oss från att radera dina uppgifter kommer vi i stället att markera dem så att de inte längre aktivt används i våra system.</p>


                      <h5>Länkar till andra hemsidor</h5>
                      <p>Denna Policy avser endast Tjänsten. Vi är inte ansvariga för andra webbsidor även om du besöker dem via en länk från Tjänsten. Om du besöker vår webbsida via en länk från en extern webbsida sida ansvarar vi inte för deras integritetspolicy och deras metoder och vi rekommenderar även i dessa fall att du läser motsvarande information som de har på deras webbsida.</p>


                      <h5>Överförande av dina personuppgifter utanför Europa</h5>
                      <p>Vi har som mål att alltid behandla dina personuppgifter inom EU/EES. Det kan dock förekomma att dina personuppgifter delas med personuppgiftsbiträden som antingen själva eller genom underleverantörer är etablerade eller lagrar information i ett land utanför EU/EES. I sådant fall kommer vi att vidta alla rimliga legala, organisatoriska och tekniska åtgärder som krävs för att säkerställa att skyddsnivån för den behandlingen motsvarar den inom EU/EES. Om du använder våra tjänster när du befinner dig utanför EU kan informationen överföras till dig utanför EU/EES med syftet att kunna tillhandahålla dig de efterfrågade tjänsterna.</p>


                      <h5>Din rättighet att anmäla klagomål till myndigheter</h5>
                      <p>Du har rätt att helt utan administrativa och/eller juridiska sanktioner anmäla klagomål till tillsynsmyndigheten, Integritetsskyddsmyndigheten om du anser att hanterandet av dina personuppgifter har brutit mot det rådande regelverket. Myndigheten som har mottagit klagomålet är därefter skyldig att informera dig om ärendets status och utfall samt huruvida en juridisk sanktion är möjlig enligt artikel 78 av Dataskyddsförordningen (GDPR).</p>


                      <h5>Cookies</h5>
                      <p>På Tjänstens webbsida (www.nya.matlistan.se) använder vi oss av så kallade cookies för att förbättra din användarupplevelse. Om du inte accepterar användningen av cookies kan funktionaliteten på Tjänsten webbsida begränsas. För mer information om cookies och liknande, se vår&nbsp;<NavLink to="cookiepolicy">Cookiepolicy</NavLink>.</p>
                  </div>

              </div>
          </div>

          <BottomBarLinks />

      </div>
    );
  }
}

export default PrivacyPolicy;
