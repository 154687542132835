import * as React from "react";
import {NavLink} from "react-router-dom";
import BottomBarLinks from "../misc/BottomBarLinks";

class Terms extends React.Component{

  componentDidMount() {
      document.title =  "Villkor | " + process.env.REACT_APP_SITE_TITLE
  }

  render() {
    return (
      <div className="terms">
          <div className="card_wrapper">
              <div className="card">
                  <h2>Villkor</h2>

                  <div>
                      <p>&nbsp;</p>
                      <p>Denna sida innehåller de villkor som gäller för användningen av Matlistan. Genom att använda dig av Matlistan godkänner du villkoren. Vad vi gör för att skydda dina rättigheter och din information kan du läsa mer om i <NavLink to="privacypolicy">integritetspolicyn</NavLink>.</p>

                      <h5>1. Allmänna regler</h5>
                      <p>Det är inte tillåtet att:</p>
                      <ul>
                          <li>Sabotera eller på annat sätt störa tjänsten.</li>
                          <li>Använda Matlistan för att göra reklam för annan tjänst eller vara, annat än vad som regleras genom andra avtal med Matlistan.</li>
                          <li>För recept som du gör tillgängliga för andra, ladda upp material som är upphovsrättsskyddad eller på annat sätt kan vara stötande.</li>
                      </ul>

                      <h5>2. Registrering</h5>
                      <p>Vid registrering måste en giltig e-postadress alternativt ditt Facebook-konto anges. Det är inte tillåtet att registrera mer än två konton per person.</p>

                      <h5>3. Matlistans rättigheter</h5>
                      <p>Vi har rätt att:</p>
                      <ul>
                          <li>Samla in och använda information på det sätt som vi anger i <NavLink to="privacypolicy">integritetspolicyn</NavLink>.</li>
                          <li>Kontakta dig för att delge information om tjänsten eller fråga dig om din upplevelse. Du har rätt att avböja denna informationsspridning.</li>
                          <li>Låta allmänheten ta del av den text och de bilder som du vid inmatningen har angivit får visas för andra.</li>
                          <li>Granska den text och de bilder du har angivit får visas för allmänheten, och ta bort sådant som vi anser bryter mot dessa användarvillkor, tredje parts upphovsrätt eller annan rättslig reglering.</li>
                      </ul>

                      <h5>4. Matlistans skyldigheter</h5>
                      <p>Vi åtar oss att behandla din personliga information enligt Dataskyddsförordningen (GDPR). För mer information om vad vi gör med dina personuppgifter, se <NavLink to="privacypolicy">integritetspolicyn</NavLink>.</p>

                      <h5>5. Matlistans ansvar</h5>
                      <p>Vi gör vårt bästa för att du alltid ska ha tillgång till din information på Matlistan. Men vi kan inte ställas till svars för eventuell skada som skett på grund att tjänsten inte fungerat som avsett.</p>

                      <h5>6. Ändring av villkor</h5>
                      <p>Vi förbehåller oss rätten att ändra dessa villkor, men ska vid sådana tillfälle meddela förändringen via e-post, vår Facebook-sida, webbsida och/eller apparna.</p>

                      <h5>7. Ansvarig ägare</h5>
                      <p>ConsumIQ AB (org.nr. 556965-9682) är ansvarig för tjänsten Matlistan och dess mobila appar.</p>

                  </div>

              </div>
          </div>

          <BottomBarLinks />

      </div>
    );
  }
}

export default Terms;
