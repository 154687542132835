import * as React from "react";
import "./dropdownmenu.scss";

export interface DropdownButtonProps {
    callback?: any;
    className?: string;
    bold?: boolean;
}

export interface DropdownButtonState {}

class DropdownButton extends React.Component<DropdownButtonProps, DropdownButtonState> {
    constructor(props: DropdownButtonProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className={`dropdown-button ${this.props.className || ""} ${this.props.bold ? "bold" : ""}`} onClick={this.props.callback}>
                {this.props.children}
            </div>
        );
    }
}

export default DropdownButton;
