import * as React from "react";
import BottomBarLinks from "../misc/BottomBarLinks";

class CookiePolicy extends React.Component {

  componentDidMount() {
      document.title =  "Cookiepolicy | " + process.env.REACT_APP_SITE_TITLE
  }

  render() {
    return (
      <div className="cookiepolicy">
          <div className="card_wrapper">
              <div className="card">
                  <h2>Cookiepolicy</h2>

                  <div>
                      <p>&nbsp;</p>
                      <p>Denna cookiepolicy (”Policyn”) beskriver hur ConsumIQ AB, org. nr 556965–9682 (”vi”, ”vår” och ”oss”), som äger och driver Nya Matlistan (”Tjänsten”), använder så kallade cookies m.m. på vår webbplats <a href="https://nya.matlistan.se/" rel="noopener noreferrer">nya.matlistan.se</a> (”Webbplatsen”).</p>
                      <p>Det kan hända att vi emellanåt behöver uppdatera eller ändra Policyn. Den senaste versionen av Policyn hittar du alltid på Webbplatsen.</p>
                      <p>Vi hoppas att Policyn besvarar dina frågor kring vår användning av cookies. Om du har ytterligare frågor eller funderingar är du alltid välkommen att kontakta oss på&nbsp;<a href="mailto:info@matlistan.se" rel="noopener noreferrer" target="_blank">info@matlistan.se</a>.</p>

                      <h5>Cookies</h5>
                      <p>En cookie är en textfil som skickas från vår webbserver och som sparas på din webbläsare eller enhet. Du har själv möjlighet att ändra inställningarna i din webbläsare för användningen och omfattningen av cookies. Om du inte accepterar att cookies används kan du stänga av cookies i din webbläsares säkerhetsinställningar. Du kan även ställa in webbläsaren så att du får en fråga varje gång vår webbsida försöker placera en cookie på din enhet. Genom webbläsaren kan också tidigare lagrade cookies raderas. Se webbläsarens hjälpsidor för mer information. Vidare kan du när som helst radera cookies manuellt från din hårddisk. Om du väljer att inte acceptera cookies kommer funktionaliteten på webbplatsen att begränsas.</p>
                      <p>På Webbplatsen används cookies till följande:</p>
                      <ul>
                          <li>Lagra information om användarens inloggning så att denne inte behöver mata in lösenordet för varje sida som besöks;</li>
                          <li>Anonym insamling av statistik över besökta sidor på www.matlistan.se;</li>
                          <li>Möjliggöra att flera personer kan besöka en butik samtidigt med samma inköpslista;</li>
                          <li>Visning av annonser med högre grad av relevans.</li>
                      </ul>

                      <h5>Cookies från tredje part</h5>
                      <p>Webbplatsen använder Google Analytics, som i sin tur använder cookies och andra liknande tekniker (exempelvis s.k<em>. Tracking pixels</em>) för att samla in information om anonymiserad användning av Webbplatsen och rapporterar trender utan att identifiera enskilda besökare. Om du vill veta mer om Google Analytics, bland annat hur du kan välja bort det, besök då&nbsp;<a href="http://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">tools.google.com/dlpage/gaoptout</a>.</p>
                  </div>

              </div>
          </div>

          <BottomBarLinks />

      </div>
    );
  }
}

export default CookiePolicy;
