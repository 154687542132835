import api from "./api";
import {APIEvents, ItemList, Item, FavoriteItem, isError, UserRecipe, deResponsify, RecipeSource, ActiveRecipe} from "./interfaces";
import {EventEmitter} from "events";

export interface Content {
    itemLists: Array<ItemList>;
    items: Array<Item>;
    favoriteItems: Array<FavoriteItem>;
    recipes: Array<UserRecipe>;
    activeRecipes: Array<ActiveRecipe>;
}

export interface NewContentEvent {}

export enum ContentEvents {
    newContent = "content",
}

export enum ContentDataType {
    itemLists = "itemLists",
    items = "items",
    favoriteItems = "favoriteItems",
    recipes = "recipes",
    activeRecipes = "activeRecipes",
}

export interface RichList extends ItemList {
    items: Array<Item>;
}

export class ContentManager {
    private fetchingDataTypes: Set<ContentDataType> = new Set<ContentDataType>();
    private emitter: EventEmitter;
    hasData: boolean = false;
    private visibleTypes: Array<ContentDataType> = [];
    private intervalId?: number;
    private visibleIntervalId?: number;

    data: Content = {
        itemLists: [],
        items: [],
        favoriteItems: [],
        recipes: [],
        activeRecipes: [],
    };

    constructor() {
        this.emitter = new EventEmitter();
        this.emitter.setMaxListeners(128);
        this.handleAuth = this.handleAuth.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchVisible = this.fetchVisible.bind(this);
        this.cacheData = this.cacheData.bind(this);

        const cachedData = this.getCachedData();
        if (cachedData !== null && api.authenticated) {
            this.data = cachedData;
            this.hasData = true;
            this.emitNewDataAndCache();
        }

        if (api.authenticated) {
            this.handleAuth();
        }
        api.addListener(APIEvents.auth, this.handleAuth);
    }

    /**
     * Used to refresh the content manager and update all data
     * @param types This should contain all types that needs to get fetched
     */
    async fetchData(
        types: Set<ContentDataType> = new Set([
            ContentDataType.favoriteItems,
            ContentDataType.itemLists,
            ContentDataType.items,
            ContentDataType.recipes,
            ContentDataType.activeRecipes,
        ])
    ) {
        try {
            types.forEach(type => {
                if (this.fetchingDataTypes.has(type)) {
                    types.delete(type);
                } else {
                    this.fetchingDataTypes.add(type);
                }
            });
            if (types.has(ContentDataType.favoriteItems)) {
                const resp = await api.items.getFavoriteItems();
                if (!isError(resp)) this.data.favoriteItems = resp.list;
            }
            if (types.has(ContentDataType.items)) {
                const resp = await api.items.getItems();
                if (!isError(resp)) this.data.items = resp.list;
            }

            if (types.has(ContentDataType.itemLists)) {
                const resp = await api.itemLists.getItemLists();
                if (!isError(resp)) this.data.itemLists = resp.list;
            }

            if (types.has(ContentDataType.activeRecipes)) {
                const resp = await api.activeRecipes.getActiveRecipes();
                if (!isError(resp)) this.data.activeRecipes = resp.list;
            }

            if (types.has(ContentDataType.recipes)) {
                const resp = await api.recipes.getRecipeBox();
                if (!isError(resp)) {
                    for (var recipeInfo of resp.list) {
                        var recipe: UserRecipe | undefined = undefined;
                        for (const item of this.data.recipes) {
                            if (item.id === recipeInfo.id) {
                                recipe = item;
                                break;
                            }
                        }
                        // The recipe already exists
                        if (recipe) {
                            // The recipe changed since it was last fetched
                            if (recipe.updatedAt !== recipeInfo.updatedAt) {
                                const resp2 = await api.recipes.getRecipe(recipeInfo.id);
                                if (!isError(resp2)) {
                                    this.patchRecipe(deResponsify(resp2));
                                }
                            }
                        }
                        // We have a new recipe to get
                        else {
                            const resp2 = await api.recipes.getRecipe(recipeInfo.id);
                            if (!isError(resp2)) {
                                this.data.recipes.push(deResponsify(resp2));
                                this.data.recipes = this.data.recipes.sort((a, b) => (a.id < b.id ? -1 : a.id === b.id ? 0 : 1));
                                this.emitNewDataAndCache();
                            }
                        }

                    }

                    // Remove local cached recipe items if they don't exist on the server.
                    for (const item of this.data.recipes) {
                        var dontExistOnServer: boolean = false;
                        for (var recipeInfoCheck of resp.list) {
                            if (item.id === recipeInfoCheck.id) {
                                dontExistOnServer = true;
                                break;
                            }
                        }
                        if (!dontExistOnServer) {
                            this.removeRecipe(item.id)
                        }

                    }
                }
            }

            types.forEach(type => {
                this.fetchingDataTypes.delete(type);
            });
            this.hasData = true;
            this.emitNewDataAndCache();
        } catch (err) {
            console.error(err);
        }
    }

    fetchVisible() {
        this.fetchData(new Set(this.visibleTypes));
    }

    emitNewDataAndCache() {
        const event: NewContentEvent = {};
        this.emitter.emit(ContentEvents.newContent, event);
        this.cacheData();
    }

    addVisibleType(type: ContentDataType) {
        this.fetchData(new Set([type]));
        this.visibleTypes.push(type);
    }

    removeVisibleType(type: ContentDataType) {
        this.visibleTypes.splice(this.visibleTypes.indexOf(type), 1);
    }

    cacheData() {
        try {
            window.localStorage.setItem("dataCache", btoa(encodeURIComponent(JSON.stringify(this.data))));
        } catch (err) {
            console.error(err);
        }
    }

    getCachedData(): Content | null {
        if ("dataCache" in window.localStorage) {
            try {
                return JSON.parse(decodeURIComponent(atob(window.localStorage.dataCache)));
            } catch (err) {
                console.warn("The cached data was malformed, ignoring it");
                return null;
            }
        }
        return null;
    }

    /**
     * Set a new default list locally (and remote), to make sure that it doesn't get desynced with the server
     */
    setDefaultList(id: number) {
        const old_default = this.data.itemLists.find(item => item.isDefault);
        const new_default = this.data.itemLists.find(item => item.id === id);
        if (new_default && old_default) {
            old_default.isDefault = false;
            new_default.isDefault = true;
        }

        api.itemLists.setDefaultList(id);
    }

    /**
     * Get the current lists with attached item arrays
     */
    getRichLists(): Array<RichList> {
        const lists: Array<RichList> = [];
        this.data.itemLists.forEach((list, index) => {
            lists.push({...list, items: []});
            for (const item of this.data.items) {
                if (item.listId === list.id) {
                    lists[index].items.push(item);
                }
            }
        });

        return lists;
    }

    getTags(): Set<string> {
        const tags: string[] = [];
        this.data.recipes.forEach(item => {
            const localTags = item.tags?.map(tag => tag.text);

            if (localTags) tags.push(...localTags);
        });
        return new Set(tags);
    }

    getSources(): RecipeSource[] {
        const sources: RecipeSource[] = [];

        this.data.recipes.forEach(item => {
            if (item.source) {
                sources.push(item.source);
            }
        });

        return sources;
    }

    getFilterSources(): Set<string> {
        const set = new Set<string>();

        this.data.recipes.forEach(item => {
            if (item.source) {
                set.add(item.source.text);
            }
        });

        return set;
    }

    getRichList(id: number): RichList | undefined {
        return this.getRichLists().find((item: RichList) => item.id === id);
    }

    getDefaultRichListId(): number | undefined {
        return this.getRichLists().find((item: RichList) => item.isDefault)?.id;
    }

    addListener(event: ContentEvents, callback: any) {
        this.emitter.addListener(event, callback);
    }

    removeListener(event: ContentEvents, callback: any) {
        this.emitter.removeListener(event, callback);
    }

    removeAllListeners(event: ContentEvents | undefined) {
        this.emitter.removeAllListeners(event);
    }

    /**
     * Remove the auth listener and interval to allow this object to be garbage collected
     */
    destroy() {
        api.removeListener(APIEvents.auth, this.handleAuth);
        clearInterval(this.intervalId);
        clearInterval(this.visibleIntervalId);
    }

    handleAuth() {
        clearInterval(this.intervalId);
        clearInterval(this.visibleIntervalId);
        if (api.authenticated) {
            this.fetchData();
            this.intervalId = window.setInterval(this.fetchData, 30000); // This "checks" if anything was changed from before, should probably be done in some other way
            this.visibleIntervalId = window.setInterval(this.fetchVisible, 5000);
        }
    }

    patchItem(item: Item) {
        this.data.items = this.data.items.map(oldItem => {
            if (oldItem.id === item.id) {
                return item;
            }
            return oldItem;
        });

        this.emitNewDataAndCache();
    }

    patchItemList(itemList: ItemList) {
        this.data.itemLists = this.data.itemLists.map(oldItemList => {
            if (oldItemList.id === itemList.id) {
                return itemList;
            }
            return oldItemList;
        });

        this.emitNewDataAndCache();
    }

    patchRecipe(recipe: UserRecipe) {
        this.data.recipes = this.data.recipes.map(oldRecipe => {
            if (oldRecipe.id === recipe.id) {
                return recipe;
            }
            return oldRecipe;
        });
        this.emitNewDataAndCache();
    }

    removeItem(id: number) {
        const index = this.data.items.findIndex(item => item.id === id);
        if (index !== -1) {
            this.data.items.splice(index, 1);
        }

        this.emitNewDataAndCache();
    }

    removeItemList(id: number) {
        const index = this.data.itemLists.findIndex(itemList => itemList.id === id);
        if (index !== -1) {
            this.data.itemLists.splice(index, 1);
        }

        this.emitNewDataAndCache();
    }

    removeRecipe(id: number) {
        const index = this.data.recipes.findIndex(recipes => recipes.id === id);
        if (index !== -1) {
            this.data.recipes.splice(index, 1);
        }

        this.emitNewDataAndCache();
    }
}

let contentManager = new ContentManager();
export default contentManager;
