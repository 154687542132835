import * as React from "react";
import "./download.scss";

import Img from "./../../img/matlistan_classic@2x.png";

class Download extends React.Component {

  componentDidMount() {
      document.title =  "" + process.env.REACT_APP_SITE_TITLE
  }

  render() {
    return (
      <div className="download old">
          <div className="wrapper">

              <div className="card">
                  <h3>Matlistan</h3>
                  <p>
                      Gör som tusentals andra svenskar gjort sedan 2014 och
                      använd Matlistan för att förenkla vardagen. </p>
                  <p>
                      Dela inköpslistor i realtid med familjen och bygg din
                      virtuella receptsamling. Varor sorteras efter din
                      lokala butiks plockordning, och blir mer träffsäker
                      för varje gång du använder Matlistan.
                  </p>
                  <a className="button full-width" href="https://play.google.com/store/apps/details?id=se.matlistan.free" target="_blank" rel="noopener noreferrer" > Hämta från Google Play </a>
                  <a className="button secondary" href="https://itunes.apple.com/se/app/matlistan/id1003750643" target="_blank" rel="noopener noreferrer" > Ladda ner från App Store </a>

              </div>
              <div className="feature_wrapper">
                  <img className="feature" alt="" src={Img} />
              </div>
          </div>

      </div>
    );
  }
}

export default Download;
