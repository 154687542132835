import * as React from "react";


interface LogoProps {
    color?: string;
}

class Logo extends React.Component<LogoProps> {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 143 27" width="143" id="header-logo">
                <defs>
                    <text id="a" fill={this.props.color? this.props.color : "#00C29B"} fontFamily="Spartan-Thin_Bold, Spartan" fontSize="22" fontWeight="bold">
                        <tspan x="56" y="68">
                            matlistan®
                        </tspan>
                    </text>
                </defs>
                <g fill={this.props.color? this.props.color : "#00C29B"} fillRule="evenodd" transform="translate(-56 -49)">
                    <use filter="url(#b)" xlinkHref="#a"></use>
                    <use xlinkHref="#a"></use>
                </g>
            </svg>
        );
    }
}

export default Logo;
