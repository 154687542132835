import React, {Component} from "react";
import "./app.scss";
import api, {MatlistanAPI} from "./api/api";
import Header from "./components/header/Header";
import {
    Switch,
    BrowserRouter as Router,
    Route,
    Redirect,
    // useLocation,
} from "react-router-dom";
import Landing from "./components/landing/Landing";
import Login from "./components/login/Login";
import {APIEvents, AuthEvent} from "./api/interfaces";
import ListsPage from "./components/lists/Lists";
import contentManager, {ContentManager} from "./api/contentManager";
import DefaultListRedirect from "./components/defaultListRedirect/DefaultListRedirect";
import DownloadNew from "./components/download/DownloadNew";
import DownloadOld from "./components/download/DownloadOld";
import ListPage from "./components/list/List";
import RecipeBoxPage from "./components/recipeBox/RecipeBox";
import RecipePage from "./components/recipe/Recipe";
import ProfilePage from "./components/profile/Profile";
import ShareLink from "./components/login/ShareLink";
import TermsPage from "./components/policy/Terms";
import PrivacyPolicyPage from "./components/policy/PrivacyPolicy";
import CookiePolicyPage from "./components/policy/CookiePolicy";
import FaqPage from "./components/faq/Faq";
import ContactPage from "./components/contact/Contact";
import CookieBarComponent from "./components/misc/CookieBar";
// TODO: Make this work (blocked by API-185)

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

// const Child = () => {
//     const params = useQuery();
//     const ticket = params.get("ticket");
//     if (ticket && !api.getAccessToken()) {
//         api.setTicket(ticket);
//         api.reauth();
//     }
//     return "";
// };

export interface AppProps {}

export interface AppState {
    authenticated: boolean;
}

declare global {
    interface Window {
        api: MatlistanAPI;
        contentManager: ContentManager;
    }
}

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {authenticated: api.authenticated};

        this.handleAuthChange = this.handleAuthChange.bind(this);
    }

    componentDidMount() {
        if (this.state.authenticated !== api.authenticated) {
            this.setState({authenticated: true});
        }
        api.addListener(APIEvents.auth, this.handleAuthChange);
        window.api = api;
        window.contentManager = contentManager;
    }

    componentWillUnmount() {
        api.removeListener(APIEvents.auth, this.handleAuthChange);
        contentManager.destroy();
    }

    handleAuthChange(e: AuthEvent) {
        this.setState({authenticated: e.authenticated});
    }

    render() {
        return (
            <>
                <div className="app">
                    <Router basename="/app">
                        {/* <Child /> */}

                        <Header authenticated={this.state.authenticated} />
                        <Switch>
                            <Route path="/" component={Landing} exact />
                            <Route path="/login" component={Login} />
                            <Route path="/contact" component={ContactPage} />
                            <Route path="/download/old" component={DownloadOld} />
                            <Route path="/download" component={DownloadNew} />
                            <Route path="/privacypolicy" component={PrivacyPolicyPage} />
                            <Route path="/cookiepolicy" component={CookiePolicyPage} />
                            <Route path="/faq/:id/:subid" component={FaqPage} />
                            <Route path="/faq" component={FaqPage} />
                            <Route path="/terms" component={TermsPage} />
                            <Route path="/Settings/Share" component={ShareLink} /> //Coming from confirmation email
                            {!this.state.authenticated ? <Redirect to="/login" /> : ""}
                            <Route path="/lists" component={ListsPage} />
                            <Route path="/list/:id" component={ListPage} />
                            <Route path="/list" component={DefaultListRedirect} />
                            <Route path="/recipes" component={RecipeBoxPage} />
                            <Route path="/recipe/:id/:action" component={RecipePage} />
                            <Route path="/recipe/:id" component={RecipePage} />
                            <Route path="/profile" component={ProfilePage} />

                            <Route path="/">
                                <div className="404 card_wrapper">
                                    <div className="card">
                                        <strong>404</strong><br/> Not Found
                                    </div>
                                </div>
                            </Route>
                        </Switch>

                        {!localStorage.acceptedCookies? <CookieBarComponent /> : ""}
                    </Router>
                </div>
            </>
        );
    }
}

export default App;
