import * as React from "react";
import "./download.scss";

import Img from "./../../img/matlistan_new@2x.png";

class Download extends React.Component{

  componentDidMount() {
      document.title =  "" + process.env.REACT_APP_SITE_TITLE
  }

  render() {
    return (
      <div className="download new">
          <div className="wrapper">

              <div className="feature_wrapper">
                  <img className="feature" alt="" src={Img} />
              </div>
              <div className="card">
                  <h3>Nya Matlistan</h3>
                  <p>
                      Nya Matlistan är en helt ny app för iOS med ambitionen
                      att bli ännu bättre än vår klassiker, Matlistan.
                      Den är fortfarande under utveckling, men du
                      kan vara med och testa den redan nu. </p>

                  <p>
                      Testandet sker via tjänsten Testflight från Apple.
                      Ladda ner appen och följ instruktionerna.
                  </p>
                  <a className="button" href="https://testflight.apple.com/join/bKRltQOM" target="_blank" rel="noopener noreferrer" > Bli Beta-testare </a>

              </div>
          </div>

      </div>
    );
  }
}

export default Download;
