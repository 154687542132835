import * as React from "react";
import "./plan.scss";
import {UserRecipe, ItemList, CustomActiveRecipe} from "../../api/interfaces";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import contentManager, {ContentDataType, ContentEvents} from "../../api/contentManager";
import api from "../../api/api";
import TagCloud from "../misc/TagCloud";

export interface PlanProps {
    recipe: UserRecipe;
    onClose?: () => void;
}

export interface PlanState {
    itemLists?: Array<ItemList>;
    planData?: CustomActiveRecipe;
}

class Plan extends React.Component<PlanProps, PlanState> {
    cloudRef = React.createRef<TagCloud>();
    fieldRef = React.createRef<HTMLInputElement>();

    constructor(props: PlanProps) {
        super(props);
        this.state = {};
        this.handleContent = this.handleContent.bind(this);
        this.handlePost = this.handlePost.bind(this);
    }

    handleContent() {
        this.setState({itemLists: contentManager.data.itemLists});
    }

    componentDidMount() {
        this.handleContent();
        contentManager.addListener(ContentEvents.newContent, this.handleContent);
        contentManager.addVisibleType(ContentDataType.itemLists);

        if (this.props.recipe) {
            this.setState({
                planData: {
                    recipeId: this.props.recipe.id,
                    ingredients: this.props.recipe.parsedIngredients?.list.map(item => {
                        return {text: item.text, isSelected: item.isProbablyNeeded};
                    }),
                },
            });
        }
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.handleContent);
        contentManager.removeVisibleType(ContentDataType.itemLists);
        document.body.style.overflow = 'inherit';
    }

    async handlePost() {
        if (this.state.planData) {
            await api.activeRecipes.createActiveRecipe(this.state.planData);
            (this.props.onClose || (() => undefined))()
        }
    }

    render() {
        return (
            <div className="modal_wrapper plan loaded">
                <div className="modal">
                    <div className="card">
                        <div className="button close" onClick={this.props.onClose} />
                        <h6>Planera</h6>
                        <h4>{this.props.recipe.title || ""}</h4>
                        <hr />
                        <div className="columns">
                            <div className="column">
                                <h6>Mängd:</h6>
                                <DropdownMenu
                                    items={
                                        this.props.recipe.selectableYields?.list.map(value => {
                                            return {
                                                text: value + " " + this.props.recipe.portionType,
                                                callback: () => {
                                                    if (this.state.planData) {
                                                        this.setState({planData: {...this.state.planData, portions: value}});
                                                    }
                                                },
                                            };
                                        }) || []
                                    }
                                    className="align-left secondary"
                                    title={
                                        (this.state.planData?.portions || this.props.recipe.selectedPortions || this.props.recipe.selectableYields?.default) +
                                        " " +
                                        this.props.recipe.portionType
                                    }
                                ></DropdownMenu>
                                <h6>Välj inköpslista:</h6>
                                <DropdownMenu
                                    className="align-left secondary"
                                    title={
                                        this.state.itemLists?.find(list => list.id === (this.state.planData?.itemListId || contentManager.getDefaultRichListId()))
                                            ?.name || ""
                                    }
                                    items={
                                        this.state.itemLists?.map(list => {
                                            return {
                                                text: list.name,
                                                callback: () => {
                                                    if (this.state.planData) {
                                                        this.setState({planData: {...this.state.planData, itemListId: list.id}});
                                                    }
                                                },
                                            };
                                        }) || []
                                    }
                                />
                                <h6>Tillfälle:</h6>
                                <TagCloud
                                    justOneActive={true}
                                    ref={this.cloudRef}
                                    tags={new Set<string>(["Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag", "Söndag"])}
                                    callback={tags => {
                                        if (tags.size > 0 && this.fieldRef.current) {
                                            this.fieldRef.current.value = "";
                                        }
                                        if (this.state.planData) {
                                            this.setState({planData: {...this.state.planData, occasion: tags.values().next().value || ""}});
                                        }
                                    }}
                                />
                                <h6>Tillfälle fritext:</h6>
                                <input
                                    type="text"
                                    className="flat-field"
                                    placeholder="Datum, tillfälle"
                                    ref={this.fieldRef}
                                    onChange={e => {
                                        if (this.state.planData) {
                                            this.setState({planData: {...this.state.planData, occasion: e.currentTarget.value}});
                                            this.cloudRef.current?.clearCloud();
                                        }
                                    }}
                                />
                            </div>
                            <div className="column">
                                <h6>Ingredienser:</h6>
                                <ul className="plan-ingredients checkboxes">
                                    {this.props.recipe.parsedIngredients?.list.map((item, index) => (
                                        <li key={index}>
                                            <input
                                                type="checkbox"
                                                id={index + "-checkbox"}
                                                defaultChecked={item.isProbablyNeeded}
                                                onChange={e => {
                                                    if (this.state.planData) {
                                                        var newIngredients = this.state.planData.ingredients;
                                                        newIngredients = newIngredients?.map(ingredient => {
                                                            if (ingredient.text === item.text) {
                                                                return {...ingredient, isSelected: e.currentTarget.checked};
                                                            } else {
                                                                return ingredient;
                                                            }
                                                        });
                                                        this.setState({planData: {...this.state.planData, ingredients: newIngredients}});
                                                    }
                                                }}
                                            />
                                            <label htmlFor={index + "-checkbox"}>{item.text}</label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="columns sticky_buttons">
                            <div>
                            </div>
                            <div>
                                <button className="button full-width" onClick={this.handlePost}>
                                    Lägg till på inköpslistan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Plan;
