import * as React from "react";
import "./tagcloud.scss";

export interface TagCloudProps {
    tags: Set<string>;
    inactiveTags?: Set<string>;
    justOneActive?: boolean;
    callback?: (activeTags: Set<string>) => void;
}

export interface TagCloudState {
    activeTags: Set<string>;
}

class TagCloud extends React.Component<TagCloudProps, TagCloudState> {
    constructor(props: TagCloudProps) {
        super(props);
        this.state = {activeTags: new Set<string>()};
    }

    executeCallback() {
        if (this.props.callback) {
            this.props.callback(this.state.activeTags);
        }
    }

    clearCloud() {
        if (this.state.activeTags.size > 0) {
            const set = this.state.activeTags;
            set.clear();
            this.setState({activeTags: set});
            this.executeCallback();
        }
    }

    toggle(tag: string) {
        if (this.props.justOneActive && !this.state.activeTags.has(tag)) {
            const set = this.state.activeTags;
            set.clear();
            this.setState({activeTags: set});
        }

        if (this.state.activeTags.has(tag)) {
            const set = this.state.activeTags;
            set.delete(tag);
            this.setState({activeTags: set}, this.executeCallback);
        } else {
            this.setState({activeTags: this.state.activeTags.add(tag)}, this.executeCallback);
        }
    }

    render() {
        for (const tag in this.state.activeTags) {
            if (!this.props.tags.has(tag)) {
                const set = this.state.activeTags;
                set.delete(tag);
                this.setState({activeTags: set}, this.executeCallback);
            }
        }
        return (
            <div className={"tag-cloud" + (this.props.callback ? " interactive" : "")}>
                {Array.from(this.props.tags).map(tag => (
                    <div
                        className={`tag ${this.state.activeTags.has(tag) ? "selected" : ""} ${
                            this.props.inactiveTags?.has(tag) && !this.state.activeTags.has(tag) ? "inactive" : ""
                        }`}
                        key={tag}
                        onClick={
                            (this.props.callback && !this.props.inactiveTags?.has(tag)) || this.state.activeTags.has(tag) ? () => this.toggle(tag) : undefined
                        }
                    >
                        {tag}
                    </div>
                ))}
            </div>
        );
    }
}

export default TagCloud;
