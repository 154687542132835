import * as React from "react";
import {Link} from "react-router-dom";
import "./cookiebar.scss";

export interface CookieBarProps {}

export interface CookieBarState {
  showBar: boolean;
}

class CookieBar extends React.Component<CookieBarProps, CookieBarState> {
    constructor(props: CookieBarProps) {
        super(props);
        this.state = {
          showBar: (localStorage.acceptedCookies? false : true)
        };
    }

    componentDidMount() {}

    acceptCookies() {
        localStorage.acceptedCookies = true;
        this.setState({showBar: false});
        //console.log("accepted cookies", this.state.showBar)
    }

    render() {
        return (
            <div className={"cookiebar " + (!this.state.showBar? "hidden": "visible")}>
                <div className="list-card">
                    <div>
                        <h6>Cookies</h6>
                        <p>Yes, vi använder cookies för att förbättra din upplevelse och ge dig mer personligt innehåll.</p>
                    </div>

                    <div className="actions">
                        <button className="button" onClick={() => this.acceptCookies()}> Acceptera och stäng</button>
                        <div>
                            <Link to="privacypolicy">Integritetspolicy</Link>
                            &nbsp; | &nbsp;
                            <Link to="cookiepolicy">Cookiepolicy</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieBar;
