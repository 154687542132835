import * as React from "react";
import contentManager, {ContentEvents, RichList, ContentDataType} from "../../api/contentManager";
import {RouteComponentProps} from "react-router-dom";
import "./list.scss";
import DropdownMenu, {DropdownItem} from "../dropdownMenu/DropdownMenu";
import api from "../../api/api";
import {isError} from "../../api/interfaces";

interface ListPropsMatchParams {
    id: string;
}

export interface ListProps extends RouteComponentProps<ListPropsMatchParams> {}

export interface ListState {
    list: RichList | undefined;
    lists: Array<RichList>;
}

class List extends React.Component<ListProps, ListState> {
    constructor(props: ListProps) {
        super(props);
        this.state = {
            list: undefined,
            lists: [],
        };
        this.updateContentState = this.updateContentState.bind(this);
        this.ItemList = this.ItemList.bind(this);
        this.getLists = this.getLists.bind(this);
    }

    async updateContentState() {
        if ("id" in this.props.match.params) {
            const num = parseInt(this.props.match.params.id);
            this.setState({
                list: contentManager.getRichList(num),
                lists: contentManager.getRichLists(),
            });

            if (this.state.list?.name) {
                document.title = this.state.list?.name + " | " + process.env.REACT_APP_SITE_TITLE
            }


            if (contentManager.getDefaultRichListId() !== num) {
                contentManager.setDefaultList(num);
            }
        }
    }

    componentDidMount() {
        document.title =  "Inköpslista | " + process.env.REACT_APP_SITE_TITLE
        contentManager.addListener(ContentEvents.newContent, this.updateContentState);

        if (contentManager.hasData) {
            this.updateContentState();
        }

        contentManager.addVisibleType(ContentDataType.items);
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.updateContentState);
        contentManager.removeVisibleType(ContentDataType.items);
    }

    componentDidUpdate(prevProps: ListProps) {
        if (prevProps.location !== this.props.location) {
            this.updateContentState();
        }
    }

    getLists(): DropdownItem[] {
        return this.state.lists.map(list => {
            return {
                text: list.name,
                className: list.id === this.state.list?.id ? "active" : "",
                callback: () => {
                    this.props.history.push("/list/" + list.id);
                },

            };
        });
    }

    ItemList(): JSX.Element {
        const items = this.state.list?.items.map(
            (item, _): JSX.Element => {
                //* Really confusing code but it works. tldr; adds <span> tags around each part of the string matching "knownItemText"
                var match: (string | JSX.Element)[] = [item.text];
                if (item.knownItemText) {
                    match = item.text.split(item.knownItemText).reduce((r: (string | JSX.Element)[], a, i) => {
                        return r.concat(a, <span key={i}>{item.knownItemText}</span>);
                    }, []);

                    match = match.slice(0, match.length - 1);
                }

                return (
                    <div className="item item-list-item" key={item.id}>
                        <div
                            className="button_action delete"
                            onClick={e => {
                                const parent = e.currentTarget.parentElement;
                                parent?.classList.add("removed");

                                api.items.deleteItem(item.id).then(() => {
                                    contentManager.removeItem(item.id);
                                });
                            }}
                        >
                            {" "}
                        </div>
                        <div
                            onClick={() => {
                                const newText = window.prompt("Ändra", item.text);
                                if (newText && this.state.list)
                                    api.items
                                        .updateItem(item.id, {text: newText, isPermanent: item.isPermanent})
                                        .then(item => (!isError(item) ? contentManager.patchItem(item) : null));
                            }}
                            className="text"
                        >
                            {match}
                        </div>
                    </div>
                );
            }
        );
        return (
            <div className="item-list">
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        const item: HTMLInputElement | null = e.currentTarget.querySelector("input");
                        if (item) {
                            api.items.createItem({
                                text: item.value,
                                listId: this.state.list?.id,
                            });
                            item.value = "";
                        }
                        contentManager.fetchData(new Set([ContentDataType.items]));
                    }}
                >
                    <input type="text" placeholder="Lägg till vara..." className="item flat-field full-width" />
                </form>
                {items}
            </div>
        );
    }

    render() {
        return (
            <div className="card_wrapper">
                <div className="card">
                    <h6>
                        <DropdownMenu
                            className="arrow"
                            items={[
                                ...this.getLists(),
                                {
                                    text: "Hantera listor",
                                    bold: true,
                                    callback: () => {
                                        this.props.history.push("/lists");
                                    },
                                },
                            ]}
                        />
                        {this.state.list?.name}
                    </h6>
                    <this.ItemList />
                </div>
            </div>
        );
    }
}

export default List;
