import * as React from "react";
import "./contact.scss";
import BottomBarLinks from "../misc/BottomBarLinks";
import api from "../../api/api";
import {isError} from "../../api/interfaces";

export interface ContactProps {}
export interface ContactState {
    name: string;
    email?: string;
    userLoggedin: boolean;
    message: string;
    newsletter: boolean;
    error: boolean;
    sent: boolean;
    authenticated: boolean;
}

class Contact extends React.Component<ContactProps, ContactState> {

    constructor(props: ContactProps) {
      super(props);
      this.state = {
          name: "",
          email: "",
          userLoggedin: false,
          message: "",
          newsletter: false,
          error: false,
          sent: false,
          authenticated: api.authenticated
      };
    }

    async fetch() {
        if (api.authenticated) {
          const me = await api.me.me();
          this.setState({email: !isError(me) && me.email ? me.email : undefined});
        }
    }

    componentDidMount() {
        document.title =  "Kontakt | " + process.env.REACT_APP_SITE_TITLE
        this.fetch();
    }

    verifyemail(){
        const email_reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!email_reg.test(String(this.state.email).toLowerCase())) {
            console.log("Email is incorrect")
            return false;
        } else {
            return true;
        }

    }

    async handleSubmit() {
        //e.preventDefault();
        this.setState({sent: false})
        this.setState({error: false})
        console.log("Submitting")

        if (!this.state.name || (this.state.name.length || 0) < 1 ||
            !this.state.email || (this.state.email.length || 0) < 1 ||
            !this.verifyemail() ||
            !this.state.message || (this.state.message.length || 0) < 1 ){

            this.setState({error: true})
            return;
        }

        // console.log("Name:", this.state.name)
        // console.log("Email:", this.state.email)
        // console.log("Message:", `${this.state.name} <${this.state.email}> wrote: \n\n${this.state.message}`)
        // console.log("Newsletter:", this.state.newsletter)
        // console.log("os:", navigator.platform)
        // console.log("osVersion:", navigator.userAgent)

        this.setState({sent: true})
        const response =  await api.me.sendFeedback({
                                    text: `${this.state.name} <${this.state.email}> wrote: \n\n${this.state.message}`,
                                    email: this.state.email,
                                    canContact: this.state.newsletter,
                                    packageName: "Web",
                                    os: navigator.platform+'',
                                    osVersion: navigator.userAgent+''
                                  })
        if (response.ok) {
            this.setState({message: ""})
        } else {
            this.setState({sent: false})
            this.setState({error: true})
            console.log("Something went wrong...");
        }
    }

    render() {
        return (
            <div className="contact">
                <div className="card_wrapper">

                    <div className="card">
                        <h2>Kontakta oss</h2>
                        <p>
                            Vi älskar att få feedback från er – skicka in ditt meddelande via
                            formuläret nedan, eller mejla oss på <a href="mailto:info@matlistan.se" target="_blank" rel="noopener noreferrer">info@matlistan.se</a>
                        </p>

                        <p className="form-message">
                            {this.state.error? "Både namn och en korrekt e-postadressen måste fyllas i." : ""}
                            {this.state.sent? "Meddelandet skickat!" : ""}
                        </p>

                        <div className="columns">
                            <div>
                                <input type="text" name="name"
                                       className={(this.state.error?"error":"") + (this.state.sent?"sent":"") }
                                       placeholder="Ditt namn"
                                       defaultValue={this.state.name}
                                       onChange={e => this.setState({name:  e.currentTarget.value})}
                                       />
                            </div>
                                <div>
                                    <input type="email" name="email"
                                           className={(this.state.error?"error":"") + (this.state.sent?"sent":"") }
                                           placeholder="Din email"
                                           defaultValue={this.state.email}
                                           disabled={this.state.authenticated}
                                           onChange={e => this.setState({email:  (this.state.authenticated? this.state.email: e.currentTarget.value)})}
                                           />
                                </div>
                        </div>
                        <textarea
                            name="message"
                            id="contactMessage"
                            className={(this.state.error?"error":"") + (this.state.sent?"sent":"") }
                            placeholder="Ditt meddelande"
                            onChange={e => this.setState({message:  e.currentTarget.value})}
                            value={this.state.message}
                        />


                        <div>
                            <input  type="checkbox"
                                    id="newsletter"
                                    onChange={e =>
                                        this.setState({
                                            newsletter: e.currentTarget.checked,
                                        })
                                    } />
                            <label htmlFor="newsletter">Prenumerera på vårt nyhetsbrev och få de senaste uppdateringarna om hur du kan förenkla din vardag.</label>
                        </div>


                        <div>
                            <button className="button" onClick={() => this.handleSubmit()}> Skicka </button>
                        </div>


                        <p> <br/><br/>
                            Vi finns även på Facebook: <a href="http://www.facebook.com/matlistan" target="_blank" rel="noopener noreferrer" > facebook.com/matlistan</a>
                        </p>
                    </div>

                </div>

                <BottomBarLinks />
            </div>
        );
    }
}

export default Contact;
