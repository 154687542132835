import * as React from "react";
import "./landing.scss";
import {NavLink} from "react-router-dom";
import BottomBarLinks from "../misc/BottomBarLinks";

import ImgTop from "./../../img/start_header@2x.png";
import ImgSparaTid from "./../../img/startpage_spara_tid.png";
import ImgInkopslistor from "./../../img/startpage_inkopslistor.png";
import ImgKokbok from "./../../img/startpage_kokbok.png";
import ImgStars from "./../../img/startpage_stars.svg";
import ImgWave from "./../../img/startpage_wave.svg";

export interface LandingProps {}
export interface LandingState {}

class Landing extends React.Component<LandingProps, LandingState> {
    constructor(props: LandingProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        document.title =  "" + process.env.REACT_APP_SITE_TITLE
    }

    scrollTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render() {
        return (
            <div className="landing">

                <div className="top_area">
                    <img alt="" src={ImgTop} />
                    <div className="text">
                        <h1> Förenkla vardagen! </h1>
                        <div> Matlistan underlättar matplanering, inköp & recepthantering.</div>
                    </div>
                </div>


                <div className="download_area content_wrapper">
                    <div className="content">
                        <div>
                            <h3>Matlistan</h3>
                            <div className="text">Vår klassiska vardagshjälte <br/>sedan 2014, för Android och iOS. </div>
                            <NavLink to="download/old" className="button">Skaffa appen</NavLink>
                        </div>
                        <div>
                            <h3 className="beta">Nya Matlistan</h3>
                            <div className="text">Din personliga hjälpreda <br/>i ny lyxförpackning, för iOS. </div>
                            <NavLink to="download" className="button">Skaffa appen</NavLink>
                        </div>
                    </div>
                </div>


                <div className="info_area content_wrapper">
                    <div className="content">
                        <div className="img">
                            <img alt="" src={ImgSparaTid}/>
                        </div>
                        <div className="text">
                            <h2>Spara tid med smarta funktioner</h2>
                            <div>Lägg till varor via mobilens röstfunktion eller manuellt. Matlistan kommer ihåg vad du brukar handla och ger förslag på lämpliga varor nästa gång det är dags. Varor sorteras efter din lokala butiks plockordning, och blir mer träffsäker för varje gång du, och dina grannar, använder den.</div>
                        </div>
                    </div>
                </div>
                <div className="info_area content_wrapper">
                    <div className="content">
                        <div className="img">
                            <img alt="" src={ImgInkopslistor}/>
                        </div>
                        <div className="text">
                            <h2>Dela inköpslistor med varandra</h2>
                            <div>Med appen kan din partner, vän eller dina barn lägga till varor inför veckohandlingen, eller som behöver kompletteras i sista minuten. <br/> Listan delas och synkas direkt i realtid.</div>
                        </div>
                    </div>
                </div>
                <div className="info_area content_wrapper">
                    <div className="content">
                        <div className="img">
                            <img alt="" src={ImgKokbok}/>
                        </div>
                        <div className="text">
                            <h2>Din digitala receptsamling</h2>
                            <div>Spara ner, redigera, kommentera och betygsätt dina favoritrecept från webben. Ingredienserna i dina recept kan du lägga till direkt i inköpslistan. Appen föreslår även vilka ingredienser du kanske redan har hemma.</div>
                        </div>
                    </div>
                </div>


                <img className="testimonial_wave" src={ImgWave} alt="" />
                <div className="testimonial_area">
                    <div className="content_wrapper">
                        <div className="content">
                            <div className="card">
                                <div className="name"> Michelle </div>
                                <h4> Wow </h4>
                                <img alt="" src={ImgStars}/>
                                <div className="text"> Hur jäkla bra var inte denna då?! Affärerna i området finns till och med – varför vet jag ej, men coolt! Och man kan kopiera ett recept rakt ner i appen! Wow!!! </div>
                            </div>
                            <div className="card">
                                <div className="name"> Morgan </div>
                                <h4> Förbättrar tillvaron </h4>
                                <img alt="" src={ImgStars}/>
                                <div className="text"> Det är få appar som förbättrar tillvaron på ett betydande sätt – detta är en av dem! Perfekt för att planera matlagning, inköp och spara recept. </div>
                            </div>
                            <div className="card">
                                <div className="name"> Magnus </div>
                                <h4> Supersmidigt </h4>
                                <img alt="" src={ImgStars}/>
                                <div className="text"> Antagligen den app jag har mest nytta av bland alla appar. Supersmidigt att flera i familjen kan skriva in i listan vad som behöver handlas. </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="bottom_area">
                    <button className="arrow" onClick={this.scrollTop} />

                    <h4>Låt oss hålla kontakten!</h4>
                    <div className="text">
                        Ta gärna <NavLink to="contact">kontakt</NavLink> med oss och låt oss veta vad <br/> du tycker om Matlistan. Vi älskar feedback!
                    </div>

                    <br/>

                    <BottomBarLinks />

                </div>

            </div>
        );
    }
}

export default Landing;
