import "./interfaces";
import {
    SessionResponse,
    APIRequestInit,
    AccountInfoRequest,
    AccountInfoResponse,
    ErrorResponse,
    isError,
    BaseResponse,
    MeResponse,
    AccountCreateRequest,
    AccountResetPassword,
    PostListRequest,
    ItemsResponse,
    PostItemsRequest,
    ItemResponse,
    PutItemsRequest,
    FavoriteItemsResponse,
    ItemListsResponse,
    APIEvents,
    AuthEvent,
    PutItemListsRequest,
    RecipeBoxResponse,
    UserRecipeResponse,
    CustomUserRecipe,
    PostUrlRecipeBoxRequest,
    ItemListResponse,
    PatchRecipeBoxRequest,
    UserLinksResponse,
    VirtualSessionRequest,
    UserLinkRequest,
    UserLinkResponse,
    ActiveRecipesResponse,
    CustomActiveRecipe,
    ActiveRecipeResponse,
    PutActiveRecipeRequest,
    PatchActiveRecipeRequest,
    Feedback,
} from "./interfaces";
import {generateUUID} from "./utils";
import {EventEmitter} from "events";

class BaseAPIModule {
    api: MatlistanAPI;
    constructor(api: MatlistanAPI) {
        this.api = api;
    }
}

class ActiveRecipeAPIModule extends BaseAPIModule {
    async getActiveRecipes(): Promise<ActiveRecipesResponse | ErrorResponse> {
        return await this.api.fetch("/ActiveRecipes");
    }

    async createActiveRecipe(data: CustomActiveRecipe): Promise<ActiveRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/ActiveRecipes", {method: "POST", body: data});
    }

    async deleteActiveRecipe(id: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/ActiveRecipes/"+id, {method: "DELETE"});
    }

    async updateActiveRecipe(id: number, data: PutActiveRecipeRequest): Promise<ActiveRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/ActiveRecipes/"+id, {method: "PUT", body: data});
    }

    async patchActiveRecipe(id: number, data: PatchActiveRecipeRequest): Promise<ActiveRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/ActiveRecipes/" + id, {method: "PATCH", body: data});
    }
}

class MeAPIModule extends BaseAPIModule {
    async me(): Promise<MeResponse | ErrorResponse> {
        return await this.api.fetch("/Me");
    }

    async getUserLinks(): Promise<UserLinksResponse | ErrorResponse> {
        return await this.api.fetch("/Me/UserLinks");
    }

    /**
     * @param email The email of the person to link your account with
     */
    async createUserLink(email: string): Promise<UserLinkResponse | ErrorResponse> {
        const data: UserLinkRequest = {isConfirming: false, email: email, linkId: this.api.accountId + generateUUID()};
        return await this.api.fetch("/Me/UserLinks", {method: "POST", body: data});
    }

    async confirmUserLink(linkId: string): Promise<UserLinkResponse | ErrorResponse> {

        const data: UserLinkRequest = {isConfirming: true, linkId: linkId};
        return await this.api.fetch("/Me/UserLinks", {method: "POST", body: data});
    }

    /**
     * Removes and creates a user link which basically resends the email.
     * @param email The email of the person who should recieve the new link
     * @param linkId The link id for the old link to remove
     */
    async resendUserLink(email: string, id: number): Promise<UserLinkResponse | ErrorResponse> {
        const resp = await this.deleteUserLink(id);
        if (!isError(resp)) {
            return await this.createUserLink(email);
        }
        return resp;
    }

    async deleteUserLink(id: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/Me/UserLinks?id=" + id, {method: "DELETE"});
    }

    async sendFeedback(data: Feedback): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/Me/Feedback", {method: "POST", body: data});
    }
}

class RecipesAPIModule extends BaseAPIModule {
    async getRecipeBox(): Promise<RecipeBoxResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox");
    }

    async getRecipe(id: number): Promise<UserRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox/" + id);
    }

    /**
     * Add a new recipe to your recipe box
     * @param data Either an url to an online recipe or a full json object containing all needed data
     */
    async createRecipe(data: CustomUserRecipe | PostUrlRecipeBoxRequest): Promise<UserRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox", {method: "POST", body: data});
    }

    async deleteRecipe(id: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox/" + id, {method: "DELETE"});
    }

    async patchRecipe(id: number, data: PatchRecipeBoxRequest): Promise<UserRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox/" + id, {method: "PATCH", body: data});
    }

    async updateRecipe(id: number, data: CustomUserRecipe): Promise<UserRecipeResponse | ErrorResponse> {
        return await this.api.fetch("/RecipeBox/" + id, {method: "PUT", body: data});
    }

    async postView(id: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/Recipes/" + id + "/Views", {method: "POST"});
    }
}

class ItemsAPIModule extends BaseAPIModule {
    async getItems(): Promise<ItemsResponse | ErrorResponse> {
        return await this.api.fetch("/Items");
    }

    async createItem(data: PostItemsRequest): Promise<ItemResponse | ErrorResponse> {
        return await this.api.fetch("/Items", {method: "POST", body: data});
    }

    async deleteItem(itemId: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/Items/" + itemId, {method: "DELETE"});
    }

    async updateItem(itemId: number, data: PutItemsRequest): Promise<ItemResponse | ErrorResponse> {
        return await this.api.fetch("/Items/" + itemId, {
            method: "PUT",
            body: data,
        });
    }

    async getFavoriteItems(): Promise<FavoriteItemsResponse | ErrorResponse> {
        return await this.api.fetch("/Items/Favorites");
    }
}

class ItemListsAPIModule extends BaseAPIModule {
    async getItemLists(): Promise<ItemListsResponse | ErrorResponse> {
        return await this.api.fetch("/ItemLists");
    }

    async createList(data: PostListRequest): Promise<ItemListsResponse | ErrorResponse> {
        return await this.api.fetch("/ItemLists", {method: "POST", body: data});
    }

    async deleteList(id: number): Promise<BaseResponse | ErrorResponse> {
        return await this.api.fetch("/ItemLists/" + id, {method: "DELETE"});
    }

    async setDefaultList(id: number): Promise<ItemListsResponse | ErrorResponse> {
        return await this.api.fetch("/ItemLists/" + id, {
            method: "PATCH",
            body: {isDefault: true},
        });
    }

    async updateList(id: number, data: PutItemListsRequest): Promise<ItemListResponse | ErrorResponse> {
        return await this.api.fetch("/ItemLists/" + id, {
            method: "PUT",
            body: data,
        });
    }
}

export class MatlistanAPI {
    private static readonly entryPoint: string = "https://api.test.matlistan.se";
    hasSavedLogin: boolean = false;
    authenticated: boolean = true;

    items: ItemsAPIModule;
    itemLists: ItemListsAPIModule;
    recipes: RecipesAPIModule;
    me: MeAPIModule;
    activeRecipes: ActiveRecipeAPIModule;

    accountId?: string;

    private emitter: EventEmitter;

    constructor() {
        this.items = new ItemsAPIModule(this);
        this.itemLists = new ItemListsAPIModule(this);
        this.recipes = new RecipesAPIModule(this);
        this.me = new MeAPIModule(this);
        this.activeRecipes = new ActiveRecipeAPIModule(this);

        this.emitter = new EventEmitter();
        this.emitter.setMaxListeners(128);

        if (this.getAccessToken()) {
            this.hasSavedLogin = true;
        }

        this.reauth();
    }

    addListener(event: APIEvents, callback: any) {
        this.emitter.addListener(event, callback);
    }

    removeListener(event: APIEvents, callback: any) {
        this.emitter.removeListener(event, callback);
    }

    removeAllListeners(event: APIEvents | undefined) {
        this.emitter.removeAllListeners(event);
    }

    /**
     * Fetch raw endpoints on the api.
     * @param base_url The relative url for the request (without the entry point)
     * @param data The data to send with the request
     * @returns Returns all data fetched from the request
     */
    async fetch(base_url: string, data: APIRequestInit = {}): Promise<BaseResponse> {
        var url: URL = new URL(base_url, MatlistanAPI.entryPoint);

        if (data.parameters) {
            Object.keys(data.parameters).forEach(key => {
                if (data.parameters && key in data.parameters) url.searchParams.append(key, data.parameters[key]);
            });
        }

        // a GET, DELETE, HEAD or OPTIONS request should not have a request body
        const asParams = ["GET", "DELETE", "HEAD", "OPTIONS"].includes(data.method || "GET");

        if (typeof data.body === "object") {
            if (asParams) {
                var form = new URLSearchParams();
                Object.keys(data.body).forEach(key => {
                    form.append(key, data.body[key]);
                });
                data.body = form;
            } else {
                data.body = JSON.stringify(data.body);
                data.headers = {
                    ...data.headers,
                    "Content-Type": "application/json",
                };
            }
        }

        data.credentials = "include";

        // if (this.authenticated) {
        //     // data.headers = {
        //     //     ...data.headers,
        //     //     // ...{Cookie: "ticket=" + this.getTicket()},  // this did not work because you cant set cookies in request
        //     //     // ...{Authentication: "Bearer " + this.getTicket()}
        //     // };
        // }
        const response: Response = await fetch(url.href, data);
        var obj = {
            statusCode: response.status,
            ok: response.ok,
            statusText: response.statusText,
            raw: response,
        };

        var json: BaseResponse;

        if (response.headers.has("content-type") && response.headers.get("content-type")?.split(";")[0].toLowerCase() === "application/json") {
            json = {...obj, ...(await response.json())};
        } else {
            json = obj;
        }

        if (response.status === 401) {
            if (!(await this.reauth())) {
                this.emitter.emit(APIEvents.auth, {type: APIEvents.auth, authenticated: false});
                throw Error("User can't authenticate!");
            }

            return await this.fetch(base_url, data);
        }

        return json;
    }

    /**
     * @returns `true` if auth was successful and `false` if not
     */
    async auth(data: VirtualSessionRequest, remember: boolean = true): Promise<SessionResponse | ErrorResponse> {
      console.log("- auth -", data)
        if (!data.deviceId) {
            data.deviceId = this.getDeviceId();
        }

        var json: SessionResponse | ErrorResponse = (await this.fetch("/Sessions", {
            method: "POST",
            body: data,
        })) as SessionResponse | ErrorResponse;

        if (!isError(json) && json.ok) {
            this.accountId = json.accountId;
        }
        const success = this.saveSession(json, remember);
        this.authenticated = success;
        if (success) {
            const event: AuthEvent = {type: APIEvents.auth, authenticated: success};
            this.emitter.emit(APIEvents.auth, event);
        }
        return json;
    }

    /**
     * @returns `true` if session was saved successfully or `false` if it failed
     */
    saveSession(json: SessionResponse | ErrorResponse, remember: boolean = true): boolean {
        if (!isError(json) && json.ok) {
            this.setAccessToken(json.accessToken, remember);
            this.setTicket(json.ticket);
            this.authenticated = true;
        } else if (json.statusCode >= 500) {
            console.error("API Error, code " + json.statusCode + " " + json.statusText);
            return false;
        } else if (json.statusCode >= 400) {
            console.warn("Error Response, code " + json.statusCode + " " + json.statusText);
            return false;
        }

        return true;
    }

    /**
     * @returns `true` if reauth was successfull and `false` if not
     */
    async reauth(): Promise<boolean> {
        if (this.getAccessToken()) return (await this.auth({accessToken: this.getAccessToken()})).ok;
        console.warn("Client tried to reauthenticate but didn't have a saved token");
        const event: AuthEvent = {type: APIEvents.auth, authenticated: false};
        this.emitter.emit(APIEvents.auth, event);
        this.authenticated = false;
        return false;
    }

    deauth() {
        this.setTicket(undefined);
        this.setAccessToken(undefined);
        this.authenticated = false;
        this.hasSavedLogin = false;

        const acceptedCookies = localStorage.acceptedCookies
        localStorage.clear();
        localStorage.acceptedCookies = acceptedCookies;
        document.cookie = "ticket=; expires=Thu, 01-Jan-70 00:00:01 GMT";

        const event: AuthEvent = {type: APIEvents.auth, authenticated: false};
        this.emitter.emit(APIEvents.auth, event);
    }

    /**
     * Check whether an account with the specified details exists
     *
     */
    async checkAccount(data: AccountInfoRequest): Promise<AccountInfoResponse | ErrorResponse> {
        return await this.fetch("/Accounts", {parameters: data});
    }

    async createAccount(data: AccountCreateRequest): Promise<SessionResponse | ErrorResponse> {
        const json: SessionResponse | ErrorResponse = await this.fetch("/Accounts", {
            method: "POST",
            body: data,
        });

        this.saveSession(json, true);

        return json;
    }

    async forgotPassword(email: string): Promise<BaseResponse | ErrorResponse> {
        return await this.fetch("/Accounts/ForgotPassword", {
            method: "POST",
            body: {email: email},
        });
    }
    async resetPassword(data: AccountResetPassword): Promise<BaseResponse | ErrorResponse> {
        return await this.fetch("/Accounts/ResetPassword", {
            method: "POST",
            body: data
        });
    }

    /**
     * Gets the device id (uuid). Generates a new id if none is found
     */
    getDeviceId(): string {
        if (!localStorage.deviceId) {
            localStorage.deviceId = generateUUID();
        }
        return localStorage.deviceId;
    }

    /**
     * Gets the access token if the user saved their login
     * @returns Returns `undefined` if no saved login is found
     */
    getAccessToken(): string | undefined {
        if (localStorage.accessToken) {
            return localStorage.accessToken;
        }

        if (sessionStorage.accessToken) {
            return sessionStorage.accessToken;
        }

        return;
    }

    getTicket(): string | undefined {
        if (sessionStorage.ticket === "undefined" || sessionStorage.ticket === "null") {
            sessionStorage.removeItem("ticket");
        }
        return sessionStorage.ticket;
    }

    setTicket(ticket: string | undefined) {
        if (ticket) {
            sessionStorage.ticket = ticket;
            document.cookie = "ticket=" + ticket + "; path=/; SameSite=None; Secure"; // this does not seem to work, might work on https
        } else {
            sessionStorage.removeItem("ticket");
            document.cookie = "ticket=; path=/; SameSite=None; Secure";
        }
    }

    setAccessToken(token: string | undefined, remember: boolean = true) {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");

        if (token) {
            if (remember) {
                localStorage.accessToken = token;
                this.hasSavedLogin = true;
            } else {
                sessionStorage.accessToken = token;
                this.hasSavedLogin = false;
            }
        }
    }
}

let api = new MatlistanAPI();
export default api;
