import * as React from "react";
import "./stars.scss";

export interface StarsProps {
    maxCount: number;
    editable?: boolean;
    value?: number;
    callback?: (value?: number) => void;
}

export interface StarProps {
    active?: boolean;
}

export interface StarsState {
    value?: number;
}

class Stars extends React.Component<StarsProps, StarsState> {
    constructor(props: StarsProps) {
        super(props);
        this.state = {value: props.value};
    }

    Star(props: StarProps) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={props.active ? "star active" : "star"}>
                <path d="M7.324 0l2.263 4.573 5.061.737-3.662 3.557.864 5.026-4.526-2.374-4.526 2.374.864-5.026L0 5.31l5.061-.737z"></path>
            </svg>
        );
    }

    updateValue(val: number) {
        if (this.props.callback) {

            // Toggle to zero/unset if pressed twize.
            if (this.state.value === val) {
                val=0;
            }
            this.setState({value: val});
            this.props.callback(val);
        }
    }


    render() {
        return (
            <div className={"star-container" + (this.props.editable? " editable" :"")}>
                {Array.from(Array(this.props.maxCount).keys()).map(index => {
                    return <span key={index} onClick={() => this.updateValue(index+1) } >
                               <this.Star active={index < (this.state.value || 0)} />
                           </span>;
                })}
            </div>
        );
    }
}

export default Stars;
