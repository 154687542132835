import * as React from "react";

import "./recipebox.scss";
import {UserRecipe, ActiveRecipe} from "../../api/interfaces";
import contentManager, {ContentEvents, ContentDataType} from "../../api/contentManager";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import {RouteComponentProps, Link} from "react-router-dom";
import api from "../../api/api";
import Loader from "./../misc/Loader";
import TagCloud from "../misc/TagCloud";
import Plan from "../plan/Plan";
import {DropdownItem} from "../dropdownMenu/DropdownMenu";
import PlanDoneModal from "../plandone/PlanDone";
import EmptyRecipeImg from "./../../img/emptyReceipe@2x.png";

interface RecipeBoxPropsMatchParams {
    id: string;
}
export interface RecipeBoxProps extends RouteComponentProps<RecipeBoxPropsMatchParams> {}

export interface RecipeBoxState {
    showfilters: boolean;
    filters: RecipeBoxFilters;
    availableTags: Set<string>;
    inactiveTags: Set<string>;
    availableSources: Set<string>;
    recipes: Array<UserRecipe>;
    activeRecipes: Array<ActiveRecipe>;
    /**
     * A list of visible recipe ids
     */
    visibleRecipes: Array<number>;
    maximumCookTime: number;
    plannedRecipe?: UserRecipe;
    showPlans: boolean;
    finishedRecipeData?: PlanDoneModalData;

    plannedFinishedRating?: number;
    plannedFinishedTime?: number;
}

export interface RecipeProps {
    recipe: UserRecipe;
    hidden?: boolean;
    occasion?:string;
}

export interface ActiveRecipeProps extends RecipeProps {
    plan: boolean;
    activeRecipe: ActiveRecipe;
    occasion?: string;
}

type RecipeBoxFilters = RatingFilter & TagFilter & SourceFilter & OwnFilter & CookTimeFilter & SearchFilter;
type RecipeBoxFilter = RatingFilter | TagFilter | SourceFilter | OwnFilter | CookTimeFilter | SearchFilter;

interface CookTimeFilter {
    maxCookTime: number;
}

interface RatingFilter {
    /**
     * Rating 0 = all recipes, 1+ means only show the ones with more than this value
     */
    rating: number;
}

interface TagFilter {
    /**
     * A set of unique tags, all recipes matching one of the tags should show up
     */
    tags: Set<string>;
}

interface SourceFilter {
    /**
     * A set of unique sources, all recipes matching at least one of the sources should show up
     */
    filterSources: Set<string>;
}

interface SearchFilter {
    searchText: string;
}

interface OwnFilter {
    own: boolean;
}

interface PlanDoneModalData {
    recipe: UserRecipe;
    activeRecipeId: number;
}

class RecipeBoxComponent extends React.Component<RecipeBoxProps, RecipeBoxState> {
    constructor(props: RecipeBoxProps) {
        super(props);
        this.handleData = this.handleData.bind(this);
        this.RecipeList = this.RecipeList.bind(this);
        this.Recipe = this.Recipe.bind(this);

        this.state = {
            maximumCookTime: 1,
            showfilters: false,
            filters: {
                tags: new Set<string>(),
                filterSources: new Set<string>(),
                maxCookTime: 0,
                rating: 0,
                own: false,
                searchText: "",
            },
            availableSources: new Set<string>(),
            availableTags: new Set<string>(),
            inactiveTags: new Set<string>(),
            recipes: [],
            activeRecipes: [],
            visibleRecipes: [],
            showPlans: (localStorage.expandedPlan==="true"? true:false),
        };
        (window as any).recipe = this;
    }

    filter() {
        var recipes = this.state.recipes;

        if (this.state.filters.own) {
            recipes = recipes.filter(recipe => recipe.isOwn);
        }

        if (this.state.filters.filterSources.size > 0) {
            recipes = recipes.filter(recipe => (recipe.source ? this.state.filters.filterSources.has(recipe.source.text) : false));
        }

        if (this.state.filters.rating > 0) {
            recipes = recipes.filter(recipe => (recipe.rating ? recipe.rating >= this.state.filters.rating : false));
        }

        if (this.state.filters.searchText) {
            const searchText = this.state.filters.searchText.toLowerCase();
            recipes = recipes.filter(recipe => recipe.ingredients?.toLowerCase().includes(searchText) || recipe.title.toLowerCase().includes(searchText));
        }

        if (this.state.filters.maxCookTime < this.state.maximumCookTime && this.state.filters.maxCookTime !== 0) {
            recipes = recipes.filter(recipe => {
                const time = recipe.cookTime || recipe.originalCookTime;
                if (time) {
                    return time <= this.state.filters.maxCookTime;
                }
                return false;
            });
        }

        if (this.state.filters.tags.size > 0) {
            for (const tag of Array.from(this.state.filters.tags.values())) {
                recipes = recipes.filter(recipe => recipe.tags?.find(t => t.text === tag));
            }
        }

        const atLeastOneHas = new Set<string>();
        for (const recipe of recipes) {
            for (const tag of recipe.tags || []) {
                atLeastOneHas.add(tag.text);
            }
        }
        var intersect = new Set(this.state.availableTags.values());
        for (const tag of Array.from(this.state.availableTags)) {
            if (atLeastOneHas.has(tag)) {
                intersect.delete(tag);
            }
        }

        this.setState({visibleRecipes: recipes.map(recipe => recipe.id), inactiveTags: intersect});
    }

    Recipe(props: RecipeProps | ActiveRecipeProps): JSX.Element {
        const url = props.recipe.imageUrls ? props.recipe.imageUrls[0].url : "";
        const items: DropdownItem[] =
            "plan" in props
                ? [
                      {
                          text: "Färdig",
                          callback: () => {
                              // api.activeRecipes.patchActiveRecipe(props.activeRecipe.id, {isCooked: true});
                              this.setState({finishedRecipeData: {recipe: props.recipe, activeRecipeId: props.activeRecipe.id}});
                          },
                      },
                      {
                          text: "Omplanera",
                          callback: () => {
                              this.setState({plannedRecipe: props.recipe});
                          },
                      },
                      {
                          text: "Ta bort från planeringen",
                          callback: () => {
                              api.activeRecipes
                                  .deleteActiveRecipe(props.activeRecipe.id)
                                  .then( () => contentManager.fetchData(new Set([ContentDataType.activeRecipes])) )
                          },
                      },
                  ]
                : [
                      {
                          text: "Planera",
                          callback: () => {
                              this.setState({plannedRecipe: props.recipe});
                          },
                      },
                      {
                          text: "Ändra",
                          callback: () => {
                              this.props.history.push("/recipe/" + props.recipe.id + "/edit");
                          },
                      },
                      {
                          text: "Ta bort",
                          callback: () => {
                              if (window.confirm("Är du säker på att du vill ta bort receptet?"))
                                  api.recipes.deleteRecipe(props.recipe.id).then(() => contentManager.removeRecipe(props.recipe.id));
                          },
                      },
                  ];
        return (
          <>
          { (!props.occasion) ?
          ""
          :
          (props.occasion === "unknown")
            ?
            <hr className="occasion"/>
            :
            <div className="occasion list-card-title">{props.occasion}</div>
          }

            <div className={"recipe-item list-card loaded" + (props.hidden ? " hidden" : "")}>
                <div className="image" style={url ?  {backgroundImage: `url(${url})`} : {}}></div>
                <div className="content">
                    <DropdownMenu items={items} />
                    <Link className="list-card-title" to={"/recipe/" + props.recipe.id}>
                        {props.recipe.title}
                    </Link>

                    <div className="list-card-content">{props.recipe.description}</div>
                </div>
            </div>
          </>
        );
    }

    RecipeList() {
        const items: JSX.Element[] = this.state.recipes.map(item => {
            return <this.Recipe key={item.id} recipe={item} hidden={!this.state.visibleRecipes.find(id => id === item.id)} />;
        });
        return <div className="recipe-list">
            { items.length ?
                items
                :
                <div className="empty">
                    <img className="retina" alt="" src={EmptyRecipeImg} />

                    <p className="title">Inga recept tillagda</p>
                    <p>
                        Lägg enkelt till nya recept genom att antingen <br/>
                        <span className="link bold" onClick={() => this.props.history.push("/recipe/add/") }> Skapa ett eget </span>
                        eller
                        <span className="link bold" onClick={() => this.addRecipeFromURL()}> Importera från URL </span>.

                        </p>
                        <p>
                        <i> Läs mer om hur under <Link to="/faq/3/0">Vanliga frågor</Link>.</i>
                    </p>
                </div>
            }
        </div>;
    }

    handleData() {
        this.setState(
            {
                recipes: contentManager.data.recipes,
                activeRecipes: contentManager.data.activeRecipes,
                filters: {
                    ...this.state.filters,
                },
                availableTags: contentManager.getTags(),
                availableSources: contentManager.getFilterSources(),
                maximumCookTime: Math.max(...contentManager.data.recipes.map(recipe => recipe.cookTime || recipe.originalCookTime || 0)),
            },
            () => this.filter()
        );
    }

    componentDidMount() {
        document.title =  "Recept | " + process.env.REACT_APP_SITE_TITLE
        this.handleData();
        contentManager.fetchData(new Set([ContentDataType.recipes]));
        contentManager.addListener(ContentEvents.newContent, this.handleData);
        contentManager.addVisibleType(ContentDataType.recipes);
        contentManager.addVisibleType(ContentDataType.activeRecipes);
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.handleData);
        contentManager.removeVisibleType(ContentDataType.recipes);
        contentManager.removeVisibleType(ContentDataType.activeRecipes);
    }

    updateFilter(filter: RecipeBoxFilter) {
        this.setState({filters: {...this.state.filters, ...filter}}, this.filter);
    }

    addRecipeFromURL () {
          const url = window.prompt("Ange URL till recept du vill lägga till:");
          if (url) {
              api.recipes.createRecipe({url: url}).then(() => {
                  contentManager.fetchData(new Set([ContentDataType.recipes]));
              });
          }
    }

    renderPlans() {
        if (this.state.showPlans) {
            var  newOccasion="", oldOccasion="";
            return this.state.activeRecipes.map((activeRecipe, index) => {
                const realRecipe = this.state.recipes.find(item => item.id === activeRecipe.recipeId);
                newOccasion = activeRecipe.occasion ? activeRecipe.occasion : "unknown" ;
                if (realRecipe) {
                    if (oldOccasion === newOccasion) {
                        newOccasion = ""
                    } else {
                        oldOccasion = newOccasion;
                    }
                    return <this.Recipe plan={true} activeRecipe={activeRecipe} key={index} recipe={realRecipe} occasion={newOccasion} />;
                } else {
                    return "";
                    // return <i>Inga recept inplanerade.</i>;
                }
            });
        } else {
            return <i>Tryck på pilen till höger för att se vad som är inplanerat.</i>;
        }
    }

    render() {
        return (
            <div className="recipe-box">
                <div className="left-col card_wrapper">
                    <div className="card filter_wrapper">
                        <div className="search">
                            <input
                                type="text"
                                className="filter-field flat-field full-width"
                                placeholder="Sök bland recept..."
                                onChange={e =>
                                    this.updateFilter({
                                        searchText: e.currentTarget.value,
                                    })
                                }
                            />
                            <div
                                className={" more-menu arrow" + (this.state.showfilters ? " open" : "")}
                                onClick={() => this.setState({showfilters: !this.state.showfilters})}>
                                  <div className="title"/>
                            </div>
                        </div>
                        <div className={" filter_extended " + (this.state.showfilters ? " open" : "")}>
                            <h6>Filtrera på egenskaper</h6>
                            <p>
                                <label htmlFor="egnarecept">Endast egna recept</label>
                                <input
                                    type="checkbox"
                                    id="egnarecept"
                                    onChange={e =>
                                        this.updateFilter({
                                            own: e.currentTarget.checked,
                                        })
                                    }
                                />
                            </p>
                            <p>
                                Tillagningstid:{" "}
                                <span>
                                    max <span>{this.state.filters.maxCookTime || "∞"}</span> min
                                </span>
                                <input
                                    type="range"
                                    className="range"
                                    step="5"
                                    min="0"
                                    max={this.state.maximumCookTime}
                                    defaultValue={this.state.maximumCookTime}
                                    onChange={e =>
                                        this.updateFilter({
                                            maxCookTime: e.currentTarget.valueAsNumber,
                                        })
                                    }
                                />
                            </p>
                            <p>
                                Betyg:{" "}
                                {this.state.filters.rating > 0 ? (
                                    <span>
                                        minst <span>{this.state.filters.rating}</span>/5
                                    </span>
                                ) : (
                                    <span>alla</span>
                                )}
                                <input
                                    type="range"
                                    className="range"
                                    min="0"
                                    max="5"
                                    defaultValue="0"
                                    onChange={e =>
                                        this.updateFilter({
                                            rating: e.currentTarget.valueAsNumber,
                                        })
                                    }
                                />
                            </p>

                            <h6>Filtrera på etiketter</h6>
                            <TagCloud
                                tags={this.state.availableTags}
                                inactiveTags={this.state.inactiveTags}
                                callback={tags => this.updateFilter({tags: tags})}
                            />

                            <h6>Filtrera efter källa</h6>
                            <TagCloud
                                tags={this.state.availableSources}
                                callback={activeSources =>
                                    this.updateFilter({
                                        filterSources: activeSources,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="right-col card_wrapper">
                    <div className="upper-right-card card">
                        <h6 className="">
                            Planering
                            <div
                                className={`more-menu arrow ${this.state.showPlans ? "open" : ""}`}
                                onClick={() => {
                                    localStorage.expandedPlan = !this.state.showPlans;
                                    this.setState({showPlans: !this.state.showPlans})
                                }}>
                                <div className="title"/>
                            </div>
                        </h6>

                        <div className="recipe-list planning-list">{this.renderPlans()}</div>
                    </div>
                    <div className="lower-right-card card recipes_wrapper">
                        <h6>
                            <DropdownMenu
                                className="add button"
                                items={[
                                    {
                                        text: "Skapa eget recept",
                                        callback: () => {
                                            this.props.history.push("/recipe/add/");
                                        },
                                    },
                                    {
                                        text: "Importera från URL",
                                        callback: () => {
                                            this.addRecipeFromURL();
                                        },
                                    },
                                ]}
                            />
                            Recept
                        </h6>

                        {this.state.recipes ? <this.RecipeList /> : <Loader />}
                        {this.state.plannedRecipe ? <Plan recipe={this.state.plannedRecipe} onClose={() => this.setState({plannedRecipe: undefined})} /> : ""}
                        {this.state.finishedRecipeData ? (
                            <PlanDoneModal data={this.state.finishedRecipeData} onClose={() => this.setState({finishedRecipeData: undefined})} />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default RecipeBoxComponent;
