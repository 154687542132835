import * as React from "react";
import contentManager, {ContentEvents} from "../../api/contentManager";
import {Redirect} from "react-router-dom";

export interface DefaultListRedirectProps {}

export interface DefaultListRedirectState {
    defaultListId: number | undefined;
}

class DefaultListRedirect extends React.Component<DefaultListRedirectProps, DefaultListRedirectState> {
    constructor(props: DefaultListRedirectProps) {
        super(props);
        this.state = {defaultListId: undefined};

        this.updateContentState = this.updateContentState.bind(this);
    }

    async updateContentState() {
        this.setState({defaultListId: contentManager.getDefaultRichListId()});
    }

    componentDidMount() {
        contentManager.addListener(ContentEvents.newContent, this.updateContentState);

        if (contentManager.hasData) {
            this.updateContentState();
        }
    }

    componentWillUnmount() {
        contentManager.removeListener(ContentEvents.newContent, this.updateContentState);
    }

    render() {
        if (!this.state.defaultListId) {
            return <div></div>;
        }
        return <Redirect to={"/list/" + this.state.defaultListId} />;
    }
}

export default DefaultListRedirect;
