import * as React from "react";
import api from "../../api/api";
import Loader from "./../misc/Loader";
import {isError} from "../../api/interfaces";
import {Link, RouteComponentProps} from "react-router-dom";

export interface DefaultListRedirectState {
    authenticated: boolean;
    confirmID: string;
    loaded: boolean;
    userLinkId?: number;
    name?: string;
    status?: string;
    accepted?: boolean;
}

export default class ShareLink extends React.Component<RouteComponentProps, DefaultListRedirectState> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { authenticated : api.authenticated, confirmID: "", loaded: false, accepted: undefined };
    }
    componentDidMount() {
        document.title =  "Länka konton | " + process.env.REACT_APP_SITE_TITLE
        this.fetchLinks();
        const query = new URLSearchParams(this.props.location.search);
        const confirm = query.get('confirm')

        if (confirm) {
            this.setState({ confirmID: confirm });
        }
    }

    async fetchLinks() {
        if (api.authenticated){
            const links = await api.me.getUserLinks();
            const links_list = isError(links) ? undefined : links.list
            this.setState({ loaded: true});
            links_list?.map(link => {
                if (link.linkId === this.state.confirmID){
                    this.setState({
                        name: link.name || link.email || "Okänd",
                        userLinkId: link.id,
                        status: link.status
                    });
                }
              })
        }
    }

    async accept() {
        // if (this.state.confirmID) api.me.confirmUserLink(this.state.confirmID).then(this.fetch);
        if (this.state.confirmID) {
            let confirm_response =  api.me.confirmUserLink(this.state.confirmID)
            if (!isError(confirm_response)) {
                this.setState({ accepted: true });
                console.log ("Accounted linked")
            }
        }
    }
    decline() {
        if (window.confirm("Är du säker på att du vill avböja länkningsförfrågan?")) {
            if (this.state.userLinkId) {
                let confirm_response =  api.me.deleteUserLink(this.state.userLinkId)
                if (!isError(confirm_response)) {
                    this.setState({ accepted: false });
                    console.log("Linking deleted")
                }
            } else {
                console.log("ID is missing!")
            }
        }
    }


    renderMissingConfirmationCode() {
          return (
            <div className="login share_link loaded">
                <div>
                    <h6 className="title">Länka konton</h6>
                    <p className="bold"> Ooops!</p>
                    <p>Länk saknas</p>
                    <br/>
                    <Link to="/" >
                        Gå till startsidan
                    </Link>
                </div>
            </div>);
    }
    renderNotSignedIn() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <p className="bold">Du är inte inloggad</p>
                  <p> Du måste vara inloggad för att kunna länka konton.</p>
                  <br/>
                  <Link to={"/login/?confirm="+ this.state.confirmID} className="button">
                      Logga in
                  </Link>
              </div>
          </div>);
    }
    renderLinked() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <p className="bold"> Era konton är länkade! </p>
                  <p> Inköpslistor och recept delas automatiskt med varandra! </p>
                  <br/>
                  <Link to="/profile" className="button">
                      Börja planera
                  </Link>
              </div>
          </div>);
    }
    renderNothingFound() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <p className="bold">Förfrågan hittades inte.</p>
                  <p> Den kan vara borttagen eller redan hanterad. </p>
                  <br/>
                  <Link to="/">
                      Gå till startsidan
                  </Link>
              </div>
          </div>);
    }
    renderApproveLink() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <div>
                      <p className="bold"> Du har fått en inbjudan om att länka ditt konto. </p>
                      <p> Länka ihop ditt konto med andra användare för att automatiskt dela inköpslistor och recept med varandra.
                          Perfekt för familjer och andra sammanboende! </p>

                      <br/>
                      <p> Förfrågan kommer från:  <b> {this.state.name} </b></p>
                      <div>
                          <button className="button secondary" onClick={() => this.decline() }>Avböj</button>
                          <button className="button"           onClick={() => this.accept() }>Acceptera</button>
                      </div>
                  </div>
              </div>
          </div>);
    }
    renderAccepted() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <p className="bold">Ditt konto är länkat med: <br/> {this.state.name} </p>
                  <p> Era inköpslistor och recept delas nu automatiskt med varandra! </p>
                  <br/>
                  <Link to="/list" className="button">
                      Börja planera
                  </Link>
              </div>
          </div>);
    }
    renderDeclined() {
        return (
          <div className="login share_link loaded">
              <div>
                  <h6 className="title">Länka konton</h6>
                  <p className="bold">Du har avböjt förfrågan om att länka konto.</p>
                  <p> Du kan när som helst länka ditt konto med din partner eller vän. Detta gör du på din <Link to="/profile">profilsida</Link>. </p>
              </div>
          </div>);
    }
    renderLoading() {
        return (
            <div className="login share_link loaded">
                <div>
                    <h6 className="title">Länka konton</h6>
                    <Loader />
                </div>
            </div>);
    }

    render() {
        if (!this.state.confirmID) {
            return this.renderMissingConfirmationCode();
        } else if(!this.state.authenticated) {
            return this.renderNotSignedIn()
        } else if (!this.state.loaded) {
            return this.renderLoading()
        } else if (this.state.accepted===true) {
            return this.renderAccepted()
        } else if (this.state.accepted===false) {
            return this.renderDeclined()
        } else if (this.state.status === "Confirmed") {
            return this.renderLinked()
        } else if (this.state.status === "Requested") {
            return this.renderApproveLink()
        } else {
            return this.renderNothingFound()
        }
    }
}
