import * as React from "react";
import "./login.scss";
import api from "../../api/api";
import {RouteComponentProps, Link} from "react-router-dom";
import {APIEvents, AuthEvent, isError} from "../../api/interfaces";


/**
 * TODO: Add the other providers
 */

export interface EmailLoginState {
    loginType: string;
    email: string;
    password: string;
    userExist: boolean;
    highlightPassword: boolean;
    highlightEmail: boolean;
    message: string;
    remember: boolean;
    terms: boolean;
    privacypolicy: boolean;
}

const navigateAfterLogin: string = "/list/"

class EmailLogin extends React.Component<RouteComponentProps, EmailLoginState> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loginType: "",
                       email: "", password: "",
                       userExist:false, remember: true,
                       highlightPassword: false, highlightEmail: false,
                       message: "",
                       terms:false,  privacypolicy: false};
        this.handleAuthChanged = this.handleAuthChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    async emailCheck(e: React.FormEvent) {
        e.preventDefault();

        const response = await api.checkAccount({email: this.state.email});
        //console.log(response)

        if (!response.ok) {
            switch (response.statusCode) {
                case 404:
                    // Will continue to next page to create a new Email-user.
                    this.setState({highlightEmail: false, userExist: false, message: "", loginType: "Email"});
                    break;
                case 406:
                    this.setState({highlightEmail: true, message: "Mejladressen är ogiltlig."});
                    break;
                default:
                    if (isError(response)) {
                        this.setState({highlightEmail: true, userExist: false, message: "Något verkar fel med adressen."});
                    }
                    break;
                }
        } else {
            if (!isError(response)) {
                  this.setState({highlightEmail: false, userExist: true, message: "",loginType: response.type}); //type: Email, Google or Facebook
            }

        }
    }

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (this.state.loginType==="Email") {

            if(!this.state.userExist) {
                if (this.state.email!=="" && this.state.password!=="" && this.state.terms && this.state.privacypolicy) {

                    if ( this.state.password.length <8 ) {
                        this.setState({highlightPassword: true, message: "Lösenordet måste innehålla minst 8 tecken."});
                    } else {
                        this.setState({highlightEmail: false, highlightPassword: false, message: ""});
                        this.handleRegister()
                    }

                }
            } else {
                if (this.state.email!=="" && this.state.password!=="") {
                    this.handleLogin()
                }
            }

        } else {
            if (this.state.email!=="")
                this.emailCheck(e)
        }

        return false;
    }

    async handleLogin() {
        const response = await api.auth(
            {
                email: this.state.email,
                password: this.state.password,
            },
            this.state.remember
        );
        if (!response.ok) {
            switch (response.statusCode) {
                case 404:
                    this.setState({highlightEmail: true, highlightPassword: false, message: "Inget konto med denna mejladress hittades."});
                    break;
                case 406:
                    this.setState({highlightEmail: false, highlightPassword: true ,message: "Ditt lösenord är felaktigt."});
                    break;
                default:
                    this.setState({highlightEmail: false, highlightPassword: true, message: "Lösenordet verkar vara fel."});
                    if (isError(response)) {
                        //console.log (response.errorString);
                    }
                    break;
            }
        } else {
            this.setState({highlightEmail: false, highlightPassword: false, message: ""});
        }
        if (api.authenticated) {
              const query = new URLSearchParams(this.props.location.search);
              const confirm = query.get('confirm')
              if (confirm){
                  this.props.history.push("/Settings/Share?confirm=" + confirm);
              } else {
                  this.props.history.push(navigateAfterLogin);
              }
        }

    }

    async handleRegister() {
        if (this.state.email && this.state.password) {
            const response = await api.createAccount({
                email: this.state.email,
                password: this.state.password,
            });
            if (!response.ok) {
                switch (response.statusCode) {
                    case 409:
                        this.setState({highlightEmail: true, highlightPassword: false, message: "Det finns redan ett konto med denna mejladress."});
                        break;
                    case 404:
                        this.setState({highlightEmail: true, highlightPassword: false, message: "Mejladressen är ogiltlig."});
                        break;
                    case 406:
                        this.setState({highlightEmail: false, highlightPassword: true, message: "Lösenordet är ogiltligt."});
                        break;
                    default:
                        if (isError(response)) {
                            this.setState({highlightEmail: true, highlightPassword: true, message: response.errorString || "Något gick fel, försök igen."});
                        } else {
                            this.setState({highlightEmail: true, highlightPassword: true, message: "Något gick fel, försök igen."});
                        }
                        break;
                }
            }
            if (api.authenticated) {
                this.setState({highlightEmail: false, highlightPassword: false, message: "Grattis! Du har registrerat ett konto. <br/> Du loggas nu in..."});
                setTimeout(() => {
                    this.handleLogin();
                }, 2000);
            }
        } else {
            this.setState({
                highlightEmail: !this.state.email,
                highlightPassword: !this.state.password,
                message: "Både e-postadressen och lösenordet måste fyllas i för att skapa ett konto.",
            });
        }
    }

    handleAuthChanged(e: AuthEvent) {
        if (e.authenticated) {
            const query = new URLSearchParams(this.props.location.search);
            const confirm = query.get('confirm')
            if (confirm){
                this.props.history.push("/Settings/Share?confirm=" + confirm);
            } else {
                this.props.history.push(navigateAfterLogin);
            }
        }
    }

    componentDidMount() {
        api.addListener(APIEvents.auth, this.handleAuthChanged);
    }

    componentWillUnmount() {
        api.removeListener(APIEvents.auth, this.handleAuthChanged);
    }

    renderEmailCheck() {
        return (
          <>
            <div id="email-login" className="login check" key="check">
                <form onSubmit={this.handleSubmit} >

                    <h6 className="title"> Ange din mejladress </h6>
                    <p className="subtitle"> &nbsp; </p>
                    <input
                        type="text"
                        name="email"
                        autoFocus
                        autoComplete="username"
                        className={"email-field full-width" + (this.state.highlightEmail ? " highlight" : "")}
                        onChange={e => this.setState({email: e.currentTarget.value})}
                        placeholder="Email"
                    />
                    <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message }} />

                    <input
                        type="submit"
                        value="Fortsätt"
                        name="checkemail"
                        className={this.state.email===""? "button disabled" : "button"}
                     />
                </form>
            </div>
            </>
        );
    }
    renderLoginNew() {
        return (
            <div id="email-login" className="login new" key="new">
                <form onSubmit={this.handleSubmit}>
                    <h6 className="title"> Ange ett nytt lösenord </h6>
                    <p className="subtitle"> Vi skapar nu ett nytt konto. </p>
                    <input
                        type="text"
                        name="email"
                        autoComplete="username"
                        value={this.state.email}
                        className={"email-field full-width check" + (this.state.highlightEmail ? " highlight" : "")}
                        disabled
                    />
                    <input
                        type="password"
                        name="password"
                        autoFocus
                        autoComplete="new-password"
                        className={"password-field full-width" + (this.state.highlightPassword ? " highlight" : "")}
                        onChange={e => this.setState({password: e.currentTarget.value})}
                        placeholder="Lösenord"
                    />

                    <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message? this.state.message : "<span>Lösenordet måste innehålla minst 8 tecken.</span>" }} />

                    <hr/>
                    <div>
                        <b>Användarvillkor & Integritetspolicy </b>
                        <p><i>Datainsamling</i></p>
                        <p>
                            För att kunna erbjuda, förbättra och utveckla Matlistan samlas viss användardata in under användning.
                            Som användare accepterar du Matlistans användarvilkor.
                            Se dessa länkar för fullständiga <Link to="/terms">Användarvillkor</Link> och <Link to="/privacypolicy">Integritetspolicy</Link>.
                        </p>
                        <div>
                            <input id="terms" type="checkbox" name="terms" onChange={e => this.setState({terms: e.currentTarget.checked})} />
                            <label htmlFor="terms" className="radio"> Jag accepterar användarvillkoren </label>
                        </div>
                        <div>
                            <input id="privacypolicy" type="checkbox" name="privacypolicy" onChange={e => this.setState({privacypolicy: e.currentTarget.checked})} />
                            <label htmlFor="privacypolicy" className="radio"> Jag accepterar integritetspolicy </label>
                        </div>
                        <p></p>
                    </div>

                    <input
                        type="submit"
                        value="Registrera"
                        name="register"
                        className={this.state.password==="" || !this.state.terms || !this.state.privacypolicy ? "button disabled" : "button"}
                     />

                </form>
            </div>
        );
    }
    renderLoginExist() {
        // Put code here which automatically redirects if logged in (like an event listener)
        return (
            <div id="email-login" className="login" key="exist">
                <form onSubmit={this.handleSubmit}>
                    <h6 className="title"> Ange ditt lösenord </h6>
                    <p className="subtitle"> Din mejl finns registrerad. </p>
                    <input
                        type="text"
                        name="email"
                        autoComplete="username"
                        defaultValue={this.state.email}
                        className={"email-field full-width check" + (this.state.highlightEmail ? " highlight" : "")}
                        disabled
                    />
                    <input
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        className={"password-field full-width" + (this.state.highlightPassword ? " highlight" : "")}
                        onChange={e => this.setState({password: e.currentTarget.value})}
                        placeholder="Lösenord"
                    />
                    <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message }} />

                    <input id="remember" type="checkbox" name="remember" onChange={e => this.setState({remember: e.currentTarget.checked})} defaultChecked />
                    <label htmlFor="remember" className="radio">
                        Håll mig inloggad
                    </label>

                    <div>
                        <input
                            type="submit"
                            value="Logga in"
                            name="login"
                            className={this.state.password===""? "button disabled" : "button"}
                         />
                        <Link to="/login/resetpassword" className="reset-link">
                            Glömt lösenordet?
                        </Link>
                     </div>
                </form>
            </div>
        );
    }
    renderEmailGoogle() {
        return (
            <div id="email-login" className="login">
                <h6>Mejladressen är upptagen</h6>
                <p className="subtitle">
                    Du har loggat in på Matlistan via <b>Google</b>. <br/>
                    Gå tillbaka och logga in den vägen i stället. <br/>
                    <br/>
                    <Link to="/login">
                        ← Tillbaka till login
                    </Link>
                </p>
            </div>
        );
    }
    renderEmailFacebbok() {
        return (
            <div id="email-login" className="login">
                <h6>Mejladressen är upptagen</h6>
                <p className="subtitle">
                    Du har loggat in på Matlistan via <b>Facebook</b>. <br/>
                    Gå tillbaka och logga in den vägen i stället. <br/>
                    <br/>
                    <Link to="/login">
                        ← Tillbaka till login
                    </Link>
                </p>
            </div>
        );
    }

        render() {
            // Put code here which automatically redirects if logged in (like an event listener)

            if (this.state.loginType==="Email") {

                if(this.state.userExist) {
                    return (this.renderLoginExist());
                } else {
                    return (this.renderLoginNew());
                }

            } else if (this.state.loginType==="Google") {
                return (this.renderEmailGoogle());
            } else if (this.state.loginType==="Facebook") {
                return (this.renderEmailFacebbok());
            } else {
                return (this.renderEmailCheck());
            }
        }

}
export default EmailLogin;
