import * as React from "react";
import {Link} from "react-router-dom";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import BottomBarLinks from "../misc/BottomBarLinks";
import Loader from "./../misc/Loader";
import "./profile.scss";
import api from "../../api/api";
import {isError, AccountType, UserLink, UserLinkStatus} from "../../api/interfaces";
import {generateUUID} from "../../api/utils";

export interface ProfileProps {}

export interface ProfileState {
    email?: string;
    links?: Array<UserLink>;
    accountType?: AccountType;
    linkEmail: string;
    sending?: string;
    message?: string;
    highlightEmail?: boolean;
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    intervalId?: number;

    constructor(props: ProfileProps) {
        super(props);
        this.state = {linkEmail: ""};
        this.fetch = this.fetch.bind(this);
        this.handleLinkSubmit = this.handleLinkSubmit.bind(this);
    }

    async fetch() {
        const me = await api.me.me();
        const links = await api.me.getUserLinks();
        if (!isError(me) && me.email) {
            const accountType = await api.checkAccount({email: me.email});
            if (!isError(accountType)) {
                this.setState({accountType: accountType.type});
            }
        }

        this.setState({email: !isError(me) && me.email ? me.email : undefined, links: isError(links) ? undefined : links.list});
    }

    componentDidMount() {
        console.log ("test")
        document.title =  "Profil | " + process.env.REACT_APP_SITE_TITLE
        this.fetch();
        this.intervalId = window.setInterval(this.fetch, 15000);
    }

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
    }

    signout() {
        if (window.confirm("Är du säker på att du vill logga ut?")) api.deauth();
    }

    resend(email?: string, id?: number) {
        if (email && id) api.me.resendUserLink(email, id);
    }

    accept(linkId?: string) {
        if (linkId) api.me.confirmUserLink(linkId).then(this.fetch);
    }

    async handleLinkSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!this.state.linkEmail) {
            this.setState({highlightEmail: false, message: ""});
            return
        }

        var _this = this;
        _this.setState({sending: "sending"})


        // TODO: Show this in the form in some way
        const response = await api.me.createUserLink(this.state.linkEmail);
        if (!response.ok) {
            switch (response.statusCode) {
                case 409:
                    this.setState({highlightEmail: true, message: "Förfrågan redan skickad."});
                    break;
                case 406:
                    this.setState({highlightEmail: true, message: "Mejladressen är ogiltlig."});
                    break;
                case 404:
                    this.setState({highlightEmail: true, message: "Länkningen kunde inte göras."});
                    break;
                case 403:
                    this.setState({highlightEmail: true, message: "Användaren har ett anonymt konto."});
                    break;
                case 400:
                    this.setState({highlightEmail: true, message: "Kan inte länka med sig själv."});
                    break;
                default:
                    this.setState({highlightEmail: true, message: "Något gick fel."});
                    break;
            }
            setTimeout(function(){
                _this.setState({sending: ""})
            }, 200);
        } else {
            setTimeout(function(){
                _this.setState({sending: "sent"})
                _this.setState({highlightEmail: false, message: ""});
                _this.setState({linkEmail: ""})  // Clears the form
            }, 1500);
            this.fetch();
        }
        setTimeout(function(){
            _this.setState({sending: ""})
        }, 3000);
    }

    renderUserLinksList(): JSX.Element | undefined {
        return (
            <ul className="user-links">
                {this.state.links?.map(link => {
                    return (
                        <li key={generateUUID()} className={"item-list-item list-card-title " + link.status.toLowerCase()}>
                            <DropdownMenu
                                items={[
                                    {
                                        text: "Ta bort",
                                        callback: () => {
                                            if (window.confirm("Är du säker på att du vill ta bort länkningen?")) {
                                                api.me.deleteUserLink(link.id).then(this.fetch);
                                            }
                                        },
                                    },
                                ]}
                            />
                            {link.name || link.email || "Okänd"}

                            {link.status === UserLinkStatus.confirmed ? <div className="status confirmed"> Länkad </div> : ""}
                            {link.status === UserLinkStatus.initiated ? (
                                <div className="status resend" onClick={() => this.resend(link.email, link.id)}>
                                    Skicka igen
                                </div>
                            ) : (
                                ""
                            )}
                            {link.status === UserLinkStatus.requested ? (
                                <>
                                    <div className="status accept" onClick={() => this.accept(link.linkId)}>
                                        Acceptera
                                    </div>
                                    <div className="tooltip">
                                        <div>
                                            <div>
                                                Du har fått en inbjudan om att länka ditt konto. <br />
                                                Acceptera för att dela inköpslistor och recept.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }

    render() {
        return (
            <div className="profile card_wrapper">
                <div className="columns">
                    <div className="card my-account">
                        <h6> Mitt konto </h6>
                        <h6 className="name"> {this.state.email || "Profil"} </h6>

                        <div className="info">
                            {this.state.accountType ? <div>
                                    {"Inloggad med "}
                                    <span className={this.state.accountType.toLowerCase()}>
                                        {this.state.accountType}
                                    </span>
                                </div> : ""}
                            <Link to="#" className="signout" onClick={this.signout}>
                                Logga ut
                            </Link>
                        </div>
                    </div>

                    <div className="card link-account">
                        <h6> Länka konto </h6>
                        <form onSubmit={this.handleLinkSubmit}>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                className={"flat-field full-width" + (this.state.highlightEmail ? " highlight" : "")}
                                value={this.state.linkEmail}
                                onChange={e => this.setState({linkEmail: e.currentTarget.value})}
                                placeholder="Länka med e-post"
                                alt="Skriv in e-postadressen för det konto du vill länka med."
                            />
                            <span className="form-message" dangerouslySetInnerHTML={{ __html: this.state.message || "" }} />

                            <button type="submit" className="button full-width" disabled={this.state.sending? true: false} >
                              { this.state.sending === "sending" ?
                                  <Loader />
                                :
                                  this.state.sending === "sent" ?
                                      "Skickad"
                                  :
                                      "Skicka inbjudan"
                              }
                            </button>
                        </form>
                        <div className="info">Mottagaren får inbjudan som en länk i ett mail och behöver inte ha appen installerad sedan tidigare.</div>
                    </div>
                </div>

                <div className="card linked-accounts">
                    <h6> Mina länkade konton </h6>
                    <div>{this.renderUserLinksList()}</div>
                </div>

                <BottomBarLinks />
            </div>
        );
    }
}

export default Profile;
