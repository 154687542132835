import * as React from "react";
import Logo from "./Logo";
import "./header.scss";
import {Link, withRouter, RouteComponentProps, NavLink} from "react-router-dom";
import DropdownMenu from "../dropdownMenu/DropdownMenu";
import api from "../../api/api";

interface HeaderProps extends RouteComponentProps {
    authenticated: boolean;
}

// interface HeaderState {
//     showNavigation: boolean
// }

class Header extends React.Component<HeaderProps> {
    // HeaderState

    _renderNav() {
        if (this.props.authenticated) {
            return (
                <div id="header-nav" className="loaded">
                    <div id="header-nav-links">
                        <NavLink to="/recipes"
                        isActive={(match, location) => {
                                      let pathStrings = location.pathname.split('/');
                                      if (match) {
                                        return true;
                                      } else if (pathStrings[1] === 'recipe') {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }}
                        > Recept</NavLink>
                        <NavLink to="/list"
                        isActive={(match, location) => {
                                      let pathStrings = location.pathname.split('/');
                                      if (match) {
                                        return true;
                                      } else if (pathStrings[1] === 'lists') {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }}
                        >Listor</NavLink>
                    </div>
                    <NavLink to="/profile" id="profileLink">
                        Profil
                    </NavLink>
                    <DropdownMenu
                        className="arrow"
                        items={[
                            {
                                text: "Recept",
                                callback: () => this.props.history.push("/recipes"),
                                className: "mobile-only",
                                bold: true,
                            },
                            {
                                text: "Listor",
                                callback: () => this.props.history.push("/list"),
                                className: "mobile-only",
                                bold: true,
                            },
                            {
                                text: "Länka konton",
                                callback: () => this.props.history.push("/profile"),
                                className: "",
                                bold: true,
                            },
                            {
                                text: "Vanliga frågor",
                                callback: () => this.props.history.push("/faq"),
                                className: "",
                            },
                            {
                                text: "Villkor",
                                callback: () => this.props.history.push("/terms"),
                                className: "",
                            },
                            {
                                text: "Integritetspolicy",
                                callback: () => this.props.history.push("/privacypolicy"),
                                className: "",
                            },
                            {
                                text: "Kontakt",
                                callback: () => this.props.history.push("/contact"),
                                className: "",
                            },
                            {
                                text: "Logga ut",
                                className: "signout",
                                callback: () => {
                                    if (window.confirm("Är du säker på att du vill logga ut?")) api.deauth();
                                },
                            },
                        ]}
                    />
                </div>
            );
        } else {
            return (
                <div id="header-nav" className="loaded">
                        <NavLink to="/login"
                        className="button secondary"
                        isActive={(match, location) => {
                                      let pathStrings = location.pathname.split('/');
                                      if (match) {
                                        return true;
                                      } else if (pathStrings[1] === 'login') {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }}
                        >Logga in</NavLink>
                    <DropdownMenu
                        className="arrow"
                        items={[
                            {
                                text: "Vanliga frågor",
                                callback: () => this.props.history.push("/faq"),
                                className: "",
                            },
                            {
                                text: "Villkor",
                                callback: () => this.props.history.push("/terms"),
                                className: "",
                            },
                            {
                                text: "Integritetspolicy",
                                callback: () => this.props.history.push("/privacypolicy"),
                                className: "",
                            },
                            {
                                text: "Kontakt",
                                callback: () => this.props.history.push("/contact"),
                                className: "",
                            },
                        ]}
                    />
                </div>
            );
        }
    }

    render() {
    var headerClassColor = "";
    if (this.props.location.pathname === "/") {
        headerClassColor = " white";
    }
        return (
            <header className={headerClassColor}>
                <Link to="/">
                    <Logo color={headerClassColor} />
                </Link>
                {this._renderNav()}
            </header>
        );
    }
}

export default withRouter(Header);
