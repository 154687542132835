export interface APIRequestInit extends RequestInit {
    parameters?: {[key: string]: any};
    body?: any;
}

export enum AccountType {
    google = "Google",
    email = "Email",
    facebook = "Facebook",
}

export enum AppType {
    ios = "Ios",
    android = "Android",
}

export enum SortOrder {
    unknown = "Unknown",
    date = "Date",
    default = "Default",
    manual = "Manual",
    grouped = "Grouped",
    store = "Store",
}

export enum BarcodeType {
    ean13 = "EAN13",
}

export enum ItemSourceMethod {
    unknown = "Unknown",
    voice = "Voice",
    manual = "Manual",
    favorite = "Favorite",
    barcode = "Barcode",
    autocomplete = "Autocomplete",
}

export enum APIEvents {
    /**
     * Callback at authentication change
     * @param {function(event: AuthEvent)} callback
     */
    auth = "auth",
}

export enum UserLinkStatus {
    initiated = "Initiated",
    requested = "Requested",
    confirmed = "Confirmed",
}

export interface APIEvent {
    type: APIEvents;
}

export interface AuthEvent extends APIEvent {
    authenticated: boolean;
}

export interface BaseResponse {
    statusCode: number;
    ok: boolean;
    raw: Response;
    statusText: string;
}

export interface AccountInfoResponse extends BaseResponse {
    type: AccountType;
}

export interface AnonymousAccountResponse extends BaseResponse {
    success: boolean;
    anonymousUid: string;
    password: string;
    ticket: string;
    errorString?: string;
    accountId: string;
}

export interface ActiveRecipe {
    id: number;
    recipeId: number;
    isPurchased: boolean;
    occasion?: string;
    occasionDate?: string;
    notes?: string;
    portions?: number;
    portions2?: string;
    ingredients?: Array<string>; // (the response string should get splitted at linebreaks)
    ingredientsMarkup?: string; // should probably get parsed into some object, and combined with the ingredients list
}

export interface ActiveRecipeResponse extends BaseResponse, ActiveRecipe {}

export interface IngredientToAdd {
    text: string;
    isSelected: boolean;
    possibleMatchText?: string;
}

export interface CustomActiveRecipe {
    recipeId: number;
    portions?: string;
    itemListId?: number;
    ingredients?: Array<IngredientToAdd>;
    occasion?: string;
    notes?: string;
}

export interface PutActiveRecipeRequest {
    portions?: string;
    itemListId?: number;
    occasion?: string;
    notes?: string;
}

export interface PatchActiveRecipeRequest {
    isCooked?: boolean;
    isPurchased?: boolean;
    occasion?: string;
}

export interface AppConfiguration {
    "e-shopping": boolean;
}

export interface AppVersionInfoResponse extends BaseResponse {
    isUpgradeNecessary: boolean;
    isUpgradeREcommended: boolean;
    message: string;
    configuration: AppConfiguration;
}

export interface ErrorResponse extends BaseResponse {
    success?: boolean;
    error?: string;
    errorString?: string;
}

export interface ActiveRecipesResponse extends BaseResponse {
    list: Array<ActiveRecipe>;
}

export interface ItemListsResponse extends BaseResponse {
    list: Array<ItemList>;
}

export interface ItemList {
    id: number;
    name: string;
    isDefault: boolean;
    sortOrder: SortOrder;
    sortByStoreId?: number;
    manualSortOderIsGrouped: boolean;
}

export interface ItemListResponse extends BaseResponse, ItemList {}

export interface ItemsResponse extends BaseResponse {
    list: Array<Item>;
}

export interface Item {
    id: number;
    text: string;
    listId: number;
    isPermanent: boolean;
    possibleMatches?: Array<string>;
    detailedPossibleMatches?: Array<PossibleMatch>;
    isPossibleMatch: boolean;
    matchingItem: string;
    selectedVariant: string;
    matchingItemText?: string;
    isDefaultMatch: boolean;
    mayBeDefaultMatch: boolean;
    isTaken: boolean;
    isChecked: boolean;
    checkedAfterStart?: number;
    placeCategory?: string;
    knownItemText?: string;
    manualSortIntex?: number;
    groupedSortIndex: number;
    addedAt: string; // timestamp
    groupedText?: string;
    searchedText?: string;
    plus: Array<string>;
    minus: Array<string>;
}

export interface ItemResponse extends BaseResponse, Item {}
export interface ItemListsResponse extends BaseResponse, Item {}

export interface FavoriteItem {
    text: string;
    matchingItem?: string;
}

export interface PossibleMatch {
    text: string;
    variant?: string;
}

export interface PostListRequest {
    name?: string;
    isPrivate?: boolean;
}

export interface PostItemsRequest {
    text?: string;
    barcode?: string;
    barcodeType?: BarcodeType;
    listId?: number;
    secondsAgo?: number;
    voiceSearchText?: string;
    source?: ItemSourceMethod;
}

export interface PutItemsRequest {
    text: string; // Not sure if optional or not
    isPermanent: boolean; // Not sure if optional or not
    matchingItem?: string;
    isDefaultMatch?: boolean;
    barcode?: string;
    barcodeType?: BarcodeType;
    listId?: number;
}

export interface FavoriteItemsResponse extends BaseResponse {
    list: Array<FavoriteItem>;
}

export interface SessionRequest extends VirtualSessionRequest {
    deviceId: string;
}

export interface VirtualSessionRequest {
    /** Will be provieded automatically when using the api */
    deviceId?: string;
    email?: string;
    password?: string;
    anonymousUid?: string;
    fbAccessToken?: string;
    gIdToken?: string;
    accessToken?: string;
}

export interface SessionResponse extends BaseResponse {
    success: boolean;
    ticket: string;
    accountId: string;
    accessToken: string;
}

export interface AccountInfoRequest {
    email?: string;
    googleId?: string;
    facebookId?: string;
}

export interface PutItemListsRequest {
    name: string;
    isDefault?: boolean;
    isPrivate?: boolean;
}

export interface AccountCreateRequest {
    email?: string;
    password?: string;
    deviceId?: string;
}

export interface AccountResetPassword {
    email: string;
    key: string;
    password: string;
    newPassword?: string;
}

export interface MeResponse extends BaseResponse {
    email?: string;
    hasPremium: boolean;
    isAdmin: boolean;
}

export interface RecipeBoxResponse extends BaseResponse {
    list: Array<RecipeInfo>;
}

export interface RecipeInfo {
    id: number;
    updatedAt: string; // Timestamp
}

export interface ImageUrl {
    imageSize: string;
    url: string;
}

export interface PostUrlRecipeBoxRequest {
    url: string;
}

export interface RecipeSource {
    originalText?: string;
    text: string;
    url?: string;
}

export interface Ingredient {
    text: string;
    knownItemText?: string;
    isCategory: boolean;
    isProbablyNeeded: boolean;
    quantityText?: string;
    unitText?: string;
    sortableText: string;
}

export interface ParsedIngredients {
    list: Array<Ingredient>;
}

export interface SelectableYields {
    list: Array<string>;
    default: string;
}

export interface Tag {
    text: string;
}

export interface FaqQuestions {
    title?: string;
    questions?: Array<FaqQuestion>;
}
export interface FaqQuestion {
    title?: string;
    body?: string;
    more?: Array<FaqQuestionMore>;
}
export interface FaqQuestionMore {
    title?: string;
    body?: string;
}

export interface UserRecipe {
    id: number;
    title: string;
    createdAt: string; // Timestamp
    isPublic: boolean;
    isOwn: boolean;
    lastViewedAt: string; // Timestamp
    rating?: number;
    cookTime?: number;
    originalCookTime?: number;
    originalCookTimeSpanLower?: number;
    imageUrls?: Array<ImageUrl>;
    description?: string;
    instructions?: string;
    instructionsMarkup?: string;
    ingredients?: string;
    ingredientsMarkup?: string;
    advice?: string;
    notes?: string;
    portions?: number;
    portionsSpanLower?: number;
    selectedPortions?: string;
    portionType?: string;
    source?: RecipeSource;
    tags?: Array<Tag>;
    lastCookedAt?: string; // Timestamp
    cookCount?: number;
    updatedAt: string; // Timestamp
    parsedIngredients?: ParsedIngredients;
    selectableYields?: SelectableYields;
}

export interface CustomUserRecipe {
    title: string;
    isPublic: boolean;
    cookTime?: number;
    description?: string;
    instructions?: string;
    ingredients?: string;
    advice?: string;
    notes?: string;
    portions?: number;
    portionType?: string;
    rating?: number;
    source?: string;
    tags?: Array<string>;
    image?: string; // Base64 image with max size of 8k x 8k pixels (jpg)
}

export interface PatchRecipeBoxRequest {
    /**
     * The user's rating of the recipe; 1-5 (inclusive)
     */
    rating?: number;
    /**
     * The user's cooking time in minutes.
     */
    cookTime?: number;
    /**
     * The user's relected number of portions for this recipe. The value is a string to enable "1/3" portions etc.
     */
    selectedPortions?: string;
}

export interface UserLink {
    /**
     * The ID of the user that is linked to. For links with status = _Initiated_, it could be 0 (zero), indicating that the target user has not registered yet.
     */
    id: number;
    /**
     * The id of the link request. Only valid when status != _Confirmed_.
     */
    linkId?: string;
    email?: string;
    fbId?: string;
    name?: string;
    status: UserLinkStatus;
}

export interface UserLinksResponse extends BaseResponse {
    list: Array<UserLink>;
}

export interface UserLinkRequest extends VirtualUserLinkRequest {
    linkId: string;
    isConfirming: boolean;
}

export interface UserLinkResponse extends BaseResponse, UserLink {}

export interface VirtualUserLinkRequest {
    linkId?: string;
    email?: string;
    isConfirming?: boolean;
}


export interface Feedback {
    text: string;
    canContact?: boolean;
    email: string;
    os?: string;
    osVersion?: string;
    packageName?: string;
    appVersionName?: string;
    appVersionCode?: string;
    device?: string;
}

export interface ConfirmUserLinkRequest extends VirtualUserLinkRequest {
    linkId: string;
}

export interface UserRecipeResponse extends BaseResponse, UserRecipe {}

/**
 * Check if an object represents an api error
 * @param object Any API Response to check if error
 */
export function isError(object: any): object is ErrorResponse {
    return "error" in object || "errorString" in object;
}

export function deResponsify(object: BaseResponse): any {
    delete object.ok;
    delete object.raw;
    delete object.statusCode;
    delete object.statusText;
    return object;
}
