import * as React from "react";
import "./plandone.scss";
import {UserRecipe} from "../../api/interfaces";
import contentManager, {ContentDataType} from "../../api/contentManager";
import api from "../../api/api";
import Stars from "../misc/Stars";

export interface PlanDoneProps {
    onClose?: () => void;
    data: PlanDoneModalData;
}

export interface PlanDoneState {
    rating?: number;
    cookTime?: number;
}

interface PlanDoneModalData {
    recipe: UserRecipe;
    activeRecipeId: number;
}
class PlanDone extends React.Component<PlanDoneProps, PlanDoneState> {

    cookTimeRef = React.createRef<HTMLInputElement>();

    constructor(props: PlanDoneProps) {
        super(props);
        this.state = {
            rating: this.props.data.recipe?.rating,
            cookTime: this.props.data.recipe?.cookTime
        };
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflow = 'inherit';
    }

    async handlePost() {
      await api.recipes.patchRecipe(this.props.data.recipe.id, {
          rating: this.state.rating,
          cookTime: this.state.cookTime
        });
        await api.activeRecipes.patchActiveRecipe(this.props.data.activeRecipeId, {isCooked: true});
        contentManager.fetchData(new Set([ContentDataType.activeRecipes]));
        (this.props.onClose || (() => undefined))()
    }

    render() {
        return (
          <div className="modal_wrapper small plandone loaded">
              <div className="modal">
                  <div className="card">
                      <div className="button close" onClick={this.props.onClose} />
                      <h6>Färdiglagad</h6>
                      <h4>{this.props.data.recipe.title}</h4>
                      <div>
                          <b>Betyg:</b>
                          <br/>
                          <Stars maxCount={5}
                              value={this.props.data.recipe.rating}
                              editable={true}
                              key={this.props.data.recipe.rating}
                              callback={ value => {this.setState({rating: value}) }
                              } />
                      </div>

                      <div>
                          <b>Tillagningstid:</b>
                          <br/>
                          <input type="number"
                              className="small"
                              ref={this.cookTimeRef}
                              placeholder="Tid i"
                              onSubmit={e => e.preventDefault()}
                              min="0"
                              defaultValue={this.state.cookTime}
                              onChange={e => this.setState({cookTime: e.currentTarget.valueAsNumber || 0})}
                              /> minuter
                      </div>

                      <div>
                          <button className="button full-width" onClick={() => this.handlePost()} >
                              Klar
                          </button>
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

export default PlanDone;
